import {
  getAllCandidates,
  statusDropdownAction,
  hackathonStatusDropdownAction,
  performOperation,
  updateInputField,
  handleCandidateEdit,
  handleCandidateStatus,
  getInterviewSlots,
  closeInterviewModal,
  scheduleInterview,
  // deleteScheduledInterview,
  candidateDetailsHide,
  getDuplicateCandidates,
  handleCandidateFlagEdit,
  getCandidatesOnTabChange,
  handleSkipRound,
  handleSkipCurrentRound,
  cancelRound,
  candidateCommentEdit,
  candidateCommentToggle,
  candidateCommentRead,
  candidateJobChangeToggle,
  candidateRoundChangeToggle,
  patchCandidateJobChange,
  handleCandidatePhotoEdit,
  setFeedbackView,
  setCandidateScreenshot,
  getCandidateFeedback,
  setCurrenCandidate,
  selectCandidateRound,
  getRecruitmentFeedback,
  postRecruitmentFeedback,
  calculateCompletion,
  getJobsForDropdown,
  getDownloadPagination,
  fetchFeedbackLoader,
  updateCandidateJobs,
  candidateJobFilter
} from "./action";

export const mapStateToProps = (state, ownProps) => {
  const {
    candidateTableLoader,
    candidates,
    panelists,
    locations,
    candidateJobDescription,
    panelist,
    changedPanelist,
    candidateScreenType,
    candidateJobs,
    proposeJob,
    candidateResetRounds,
    candidateAgencies,
    candidateStatus,
    candidateInfo,
    candidateOriginalInfo,
    candidateAgency,
    candidateViewModal,
    cancelButton,
    candidateEmailModal,
    candidateEmailSuggestion,
    editCandidateView,
    candidateJobChangeModal,
    candidateShortlistedLoader,
    interviewModal,
    interviewSubmitLoader,
    interviewOnModalLoader,
    interviewType,
    interviewDate,
    interviewAvailableSlots,
    interviewPanelistName,
    interviewSelfBookedSlots,
    interviewSchedule,
    interviewDeleteLoader,
    interviewConfirmModal,
    candidateInterviewMode,
    candidatePDFViewModal,
    candidateResume,
    candidateImageViewModal,
    candidateImage,
    candidateRejectedLoader,
    candidateDateDropdown,
    candidateDateRange,
    candidateStatusDropdown,
    candidateHackathonStatusDropdown,
    candidateFilterStatus,
    candidateFilterHackathonStatus,
    candidateRound,
    candidateExperienceMax,
    recordId,
    reasonVal,
    otherReasonVal,
    candidateExperienceMin,
    duplicateViewModal,
    duplicateCandidates,
    candidateOngoingStatus,
    candidateRejectedStatus,
    candidateNewPageNumber,
    candidateupdatedResume,
    candidateOngoingPageNumber,
    candidateRejectedPageNumber,
    candidateHistoryPageNumber,
    candidateCommentBool,
    candidateCommentText,
    candidateCommentLoaderBool,
    candidateJobDescriptionForHistory,
    candidateAgencyForHistory,
    candidateJobChange,
    candidateRoundChange,
    candidateJobChangeToggleBool,
    candidateJobChangeLoaderBool,
    candidatePhotoEdit,
    candidatePhotoLoader,
    candidatePhotoEditBool,
    candidatePageMeta,
    viewFeedback,
    feedbackLoader,
    candidateRounds,
    candidatesScreenshot,
    candidateFeedbackRound,
    candidateFeedback,
    duplicateCandFilterStatus,
    feedbackCandidate,
    feedbackPageLoader,
    feedbackCandidateInfo,
    feedbackSubmitLoader,
    feedbackStatus,
    feedbackInterviewMode,
    feedbackScrollPosition,
    feedbackSubmitted,
    feedbackCandidateId,
    feedbackQuestions,
    feedbackJobInterviewer,
    feedbackActions,
    feedbackCount,
    feedbackErrorPage,
    feedbackQuestionNotFound,
    maritalStatusList,
    automotiveBackground,
    hrFeedbackModal,
    addPreviousOrgModal,
    filterStatusOngoing,
    getAllCandidates,
    candidateProfiles,
    candidateTableSearch,
    shortlisterComment,
    jobStatus,
    totalExperiencedVacancies,
    candidateMailTo,
    selectedRow
  } = state.candidate;
  const { user } = state.session;
  return {
    filterStatusOngoing,
    candidateTableLoader,
    candidates,
    candidateJobDescription,
    panelist,
    duplicateCandFilterStatus,
    changedPanelist,
    candidateScreenType,
    candidateJobs,
    proposeJob,
    candidateResetRounds,
    candidateAgencies,
    candidateStatus,
    candidateInfo,
    candidateOriginalInfo,
    candidateAgency,
    candidateViewModal,
    cancelButton,
    candidateEmailModal,
    candidateEmailSuggestion,
    editCandidateView,
    candidateJobChangeModal,
    candidateShortlistedLoader,
    interviewSubmitLoader,
    interviewOnModalLoader,
    interviewModal,
    interviewType,
    interviewDate,
    interviewAvailableSlots,
    interviewPanelistName,
    interviewSelfBookedSlots,
    interviewSchedule,
    interviewDeleteLoader,
    interviewConfirmModal,
    candidateInterviewMode,
    candidatePDFViewModal,
    candidateResume,
    candidateImageViewModal,
    candidateImage,
    candidateRejectedLoader,
    candidateDateDropdown,
    candidateDateRange,
    candidateStatusDropdown,
    candidateHackathonStatusDropdown,
    candidateFilterStatus,
    candidateFilterHackathonStatus,
    candidateRound,
    candidateExperienceMax,
    recordId,
    reasonVal,
    otherReasonVal,
    candidateExperienceMin,
   
    duplicateViewModal,
    duplicateCandidates,
    user,
    candidateRejectedStatus,
    candidateOngoingStatus,
    panelists,
    locations,
    candidateNewPageNumber,
    candidateupdatedResume,
    candidateOngoingPageNumber,
    candidateRejectedPageNumber,
    candidateHistoryPageNumber,
    candidateCommentBool,
    candidateCommentText,
    candidateCommentLoaderBool,
    candidateJobDescriptionForHistory,
    candidateAgencyForHistory,
    candidateJobChange,
    candidateRoundChange,
    candidateJobChangeToggleBool,
    candidateJobChangeLoaderBool,
    candidatePhotoEdit,
    candidatePhotoLoader,
    candidatePhotoEditBool,
    candidatePageMeta,
    viewFeedback,
    feedbackLoader,
    candidateRounds,
    candidatesScreenshot,
    candidateFeedbackRound,
    candidateFeedback,

    feedbackCandidate,
    feedbackPageLoader,
    feedbackCandidateInfo,
    feedbackSubmitLoader,
    feedbackStatus,
    feedbackInterviewMode,
    history: ownProps.history,
    feedbackScrollPosition,
    feedbackSubmitted,
    feedbackCandidateId,
    feedbackQuestions,
    feedbackJobInterviewer,
    feedbackActions,
    feedbackCount,
    feedbackErrorPage,
    feedbackQuestionNotFound,
    maritalStatusList,
    automotiveBackground,
    hrFeedbackModal,
    addPreviousOrgModal,
    candidateProfiles,
    candidateTableSearch,
    shortlisterComment,
    jobStatus,
    totalExperiencedVacancies,
    candidateMailTo,
    selectedRow
  };
};

export const mapDispatchToProps = (dispatch) => ({
  fetchFeedbackLoader: (bool) => dispatch(fetchFeedbackLoader(bool)),
  getJobsForDropdown: () => dispatch(getJobsForDropdown()),
  getAllCandidates: (history,page, pagelimit) =>dispatch(getAllCandidates(history,page, pagelimit)),
  getDownloadPagination: () => dispatch(getDownloadPagination()),
  getCandidatesOnTabChange: (history) =>
   dispatch(getCandidatesOnTabChange(history)),
  getDuplicateCandidates: () => dispatch(getDuplicateCandidates()),
  statusDropdownAction: (history) => dispatch(statusDropdownAction(history)),
  hackathonStatusDropdownAction: (history) => dispatch(hackathonStatusDropdownAction(history)),
  handleCandidateEdit: () => dispatch(handleCandidateEdit()),
  handleCandidateFlagEdit: () => dispatch(handleCandidateFlagEdit()),
  handleCandidateStatus: (id, status, comment,priority) =>
    dispatch(handleCandidateStatus(id, status, comment,priority)),
  candidateDetailsHide: (id) => dispatch(candidateDetailsHide(id)),
  handleSkipRound: (id,status) => dispatch(handleSkipRound(id,status)),
  handleSkipCurrentRound: (id,status) => dispatch(handleSkipCurrentRound(id,status)),
  cancelRound: (id,reason) => dispatch(cancelRound(id,reason)),
  handleCandidatePhotoEdit: () => dispatch(handleCandidatePhotoEdit()),

  getInterviewSlots: () => dispatch(getInterviewSlots()),
  closeInterviewModal: () => dispatch(closeInterviewModal()),
  scheduleInterview: () => dispatch(scheduleInterview()),
  // deleteScheduledInterview: ((values) =>{ 
   
  //   return dispatch(deleteScheduledInterview(values)
  // )}),

  updateInputField: (inputFieldType, value) =>
    dispatch(updateInputField(inputFieldType, value)),
  performOperation: (operationType, value) =>
    dispatch(performOperation(operationType, value)),
  candidateCommentEdit: (id, text, history) =>
    dispatch(candidateCommentEdit(id, text, history)),
  candidateCommentToggle: (id, text) =>
    dispatch(candidateCommentToggle(id, text)),
  candidateCommentRead: (input) => dispatch(candidateCommentRead(input)),
  candidateRoundChangeToggle: (bool, obj) =>
    dispatch(candidateRoundChangeToggle(bool, obj)),
  candidateJobChangeToggle: (bool, obj) =>
    dispatch(candidateJobChangeToggle(bool, obj)),
  patchCandidateJobChange: () => dispatch(patchCandidateJobChange()),
  setFeedbackView: (bool) => dispatch(setFeedbackView(bool)),
  setCandidateScreenshot: (value) => dispatch(setCandidateScreenshot(value)),
  getCandidateFeedback: (candidateId) =>
    dispatch(getCandidateFeedback(candidateId)),
  setCurrenCandidate: (data) => dispatch(setCurrenCandidate(data)),
  selectCandidateRound: (val) => dispatch(selectCandidateRound(val)),

  getRecruitmentFeedback: () => dispatch(getRecruitmentFeedback()),
  postRecruitmentFeedback: () => dispatch(postRecruitmentFeedback()),
  calculateCompletion: () => dispatch(calculateCompletion()),
  updateCandidateJobs: (val) => dispatch(updateCandidateJobs(val)),
  candidateJobFilter: (val) => dispatch(candidateJobFilter(val))
});
