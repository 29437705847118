import { Api, Notification, SEARCH_VALUE } from "services";
import moment from "moment";
import { MdNotInterested } from "react-icons/md";
import { getAllCandidates as getAllHrProcessCandidates } from "../../hr-process/redux/action";
import { HrFeedbackActions } from "../feedback/store";
import store from "store";
export const CANDIDATE_TABLE_LOADER = "CANDIDATE_TABLE_LOADER";
export const CANDIDATE_SCREEN_TYPE = "CANDIDATE_SCREEN_TYPE";
export const DUPLICATE_CANDIDATE_STATUS = "DUPLICATE_CANDIDATE_STATUS";
export const CANDIDATE_VIEW_MODAL = "CANDIDATE_VIEW_MODAL";
export const CANDIDATE_JOB_CHANGE_MODAL = "CANDIDATE_JOB_CHANGE_MODAL";
export const CANDIDATE_SHORTLISTED_LOADER = "CANDIDATE_SHORTLISTED_LOADER";
export const CANDIDATE_REJECTED_LOADER = "CANDIDATE_REJECTED_LOADER";
export const INTERVIEW_MODAL = "INTERVIEW_MODAL";
export const INTERVIEW_SUBMIT_LOADER = "INTERVIEW_SUBMIT_LOADER";
export const INTERVIEW_ONMODAL_LOADER = "INTERVIEW_ONMODAL_LOADER";
export const INTERVIEW_DELETE_LOADER = "INTERVIEW_DELETE_LOADER";
export const INTERVIEW_CONFIRM_MODAL = "INTERVIEW_CONFIRM_MODAL";
export const CANDIDATE_PDF_VIEW_MODAL = "CANDIDATE_PDF_VIEW_MODAL";
export const CANDIDATE_IMAGE_VIEW_MODAL = "CANDIDATE_IMAGE_VIEW_MODAL";
export const DUPLICATE_VIEW_MODAL = "DUPLICATE_VIEW_MODAL";
export const CANDIDATE_PHOTO_LOADER = "CANDIDATE_PHOTO_LOADER";
export const CANDIDATE_PHOTO_EDIT_BOOL = "CANDIDATE_PHOTO_EDIT_BOOL";

export const CANCEL_BUTTON = "CANCEL_BUTTON";
export const RECORD_ID = "RECORD_ID";
export const REASON_VAL = "REASON_VAL";
export const OTHER_REASON_VAL = "OTHER_REASON_VAL";

export const CANDIDATE_ROUND_CHANGE = "CANDIDATE_ROUND_CHANGE";
export const CANDIDATE_JOB_CHANGE = "CANDIDATE_JOB_CHANGE";
export const CANDIDATE_JOB_DESCRIPTION = "CANDIDATE_JOB_DESCRIPTION";
export const PANELIST = "PANELIST";
export const CHANGED_PANELIST = "CHANGED_PANELIST";
export const CANDIDATE_JOB_DESCRIPTION_FOR_HISTORY =
  "CANDIDATE_JOB_DESCRIPTION_FOR_HISTORY";
export const CANDIDATE_AGENCIES = "CANDIDATE_AGENCIES";
export const CANDIDATE_AGENCY = "CANDIDATE_AGENCY";
export const CANDIDATE_AGENCY_FOR_HISTORY = "CANDIDATE_AGENCY_FOR_HISTORY";
export const INTERVIEW_TYPE = "INTERVIEW_TYPE";
export const INTERVIEW_SCHEDULE = "INTERVIEW_SCHEDULE";
export const INTERVIEW_DATE = "INTERVIEW_DATE";
export const INTERVIEW_AVAILABLE_SLOTS = "INTERVIEW_AVAILABLE_SLOTS";
export const INTERVIEW_PANELIST_NAME = "INTERVIEW_PANELIST_NAME";
export const INTERVIEW_SELF_BOOKED_SLOTS = "INTERVIEW_SELF_BOOKED_SLOTS";
export const CANDIDATE_FEEDBACK = "CANDIDATE_FEEDBACK";
export const CANDIDATE_CLEAR = "CANDIDATE_CLEAR";
export const CANDIDATE_RESUME = "CANDIDATE_RESUME";
export const CANDIDATE_IMAGE = "CANDIDATE_IMAGE";
export const CANDIDATE_DATE_DROPDOWN = "CANDIDATE_DATE_DROPDOWN";
export const CANDIDATE_DATE_RANGE = "CANDIDATE_DATE_RANGE";
export const CANDIDATE_STATUS_DROPDOWN = "CANDIDATE_STATUS_DROPDOWN";
export const CANDIDATE_HACKATHON_STATUS_DROPDOWN = "CANDIDATE_HACKATHON_STATUS_DROPDOWN";
export const CANDIDATE_FILTER_STATUS = "CANDIDATE_FILTER_STATUS";
export const CANDIDATE_FILTER_HACKATHON_STATUS = "CANDIDATE_FILTER_HACKATHON_STATUS";
export const CANDIDATE_ROUND = "CANDIDATE_ROUND";
export const CANDIDATE_NEW_PAGE_NUMBER = "CANDIDATE_NEW_PAGE_NUMBER";
export const CANDIDATE_UPDATE_RESUME = "CANDIDATE_UPDATE_RESUME";
export const CANDIDATE_ONGOING_PAGE_NUMBER = "CANDIDATE_ONGOING_PAGE_NUMBER";
export const CANDIDATE_REJECTED_PAGE_NUMBER = "CANDIDATE_REJECTED_PAGE_NUMBER";
export const CANDIDATE_HISTORY_PAGE_NUMBER = "CANDIDATE_HISTORY_PAGE_NUMBER";
export const CANDIDATE_PHOTO_EDIT = "CANDIDATE_PHOTO_EDIT";
export const CANDIDATE_SHORTLISTER_NOTE = "CANDIDATE_SHORTLISTER_NOTE";
export const CANDIDATE_PRIORITY="CANDIDATE_PRIORITY";

export const CANDIDATE_RECRUITMENT_STATUS = "CANDIDATE_RECRUITMENT_STATUS";
export const CANDIDATE_RECRUITMENT_ROUND = "CANDIDATE_RECRUITMENT_ROUND";

export const CANDIDATE_BLACKLIST = "CANDIDATE_BLACKLIST";
export const CANDIDATE_DUPLICATE = "CANDIDATE_DUPLICATE";

export const CANDIDATE_DETAILS = "CANDIDATE_DETAILS";

// Experience Candidates data
export const ADD_CANDIDATE_DETAILS = "ADD_CANDIDATE_DETAILS";
export const CANDIDATE_EMAIL_MODAL = "CANDIDATE_EMAIL_MODAL";
export const CANDIDATE_EMAIL_SUGGESTIONS = "CANDIDATE_EMAIL_SUGGESTIONS";
export const MAIL_LOADING_UI = "MAIL_LOADING_UI";
export const EDIT_CANDIDATE_VIEW = "EDIT_CANDIDATE_VIEW";
export const CANDIDATE_MAIL_TO = "CANDIDATE_MAIL_TO";
export const SELECTED_ROW = "SELECTED_ROW";
export const CANDIDATE_SUGGESTED_EMAIL = "CANDIDATE_SUGGESTED_EMAIL";
export const CANDIDATE_JOB_FILTER = "CANDIDATE_JOB_FILTER";
export const ADD_PROFILE_MODAL = "ADD_PROFILE_MODAL";
export const CANDIDATE_EXPERIENCE_MAX = "CANDIDATE_EXPERIENCE_MAX";
export const CANDIDATE_EXPERIENCE_MIN = "CANDIDATE_EXPERIENCE_MIN";
export const EXPERIENCED_VACANCIES = "EXPERIENCED_VACANCIES";
export const TOTAL_EXPERIENCED_VACANCIES = "TOTAL_EXPERIENCED_VACANCIES" 
export const CANDIDATE_PROFILES = "CANDIDATE_PROFILES";
export const CANDIDATE_TABLE_SEARCH = "CANDIDATE_TABLE_SEARCH";
export const SHORTLISTER_COMMENT="SHORTLISTER_COMMENT";
export const CANDIDATE_JOB_STATUS = "CANDIDATE_JOB_STATUS";

export const CANDIDATE_SKIP_ROUND = "CANDIDATE_SKIP_ROUND";
export const CANDIDATE_CANCEL_ROUND = "CANDIDATE_CANCEL_ROUND";
export const DUPLICATE_CANDIDATE_GET = "DUPLICATE_CANDIDATE_GET";
export const CANDIDATE_DETAILS_GET = "CANDIDATE_DETAILS_GET";
export const CANDIDATE_DETAILS_EDIT = "CANDIDATE_DETAILS_EDIT";
export const CANDIDATE_RECRUITMENT_EDIT = "CANDIDATE_RECRUITMENT_EDIT";
export const CANDIDATE_DETAILS_HIDE = "CANDIDATE_DETAILS_HIDE";
export const UPDATE_CANDIDATE_JOBS = "UPDATE_CANDIDATE_JOBS";

export const INTERVIEW_SLOTS_DELETE = "INTERVIEW_SLOTS_DELETE";
export const INTERVIEW_SLOTS_ADD = "INTERVIEW_SLOTS_ADD";
export const PANELIST_NAME_CHANGED = "PANELIST_NAME_CHANGED";
export const CANDIDATE_COMMENT_TOGGLE = "CANDIDATE_COMMENT_TOGGLE";
export const CANDIDATE_COMMENT_READ = "CANDIDATE_COMMENT_READ";
export const CANDIDATE_COMMENT_LOADER = "CANDIDATE_COMMENT_LOADER";
export const CANDIDATE_COMMENT_UPDATE_LOCAL = "CANDIDATE_COMMENT_UPDATE_LOCAL";
export const CANDIDATE_JOB_CHANGE_TOGGLE = "CANDIDATE_JOB_CHANGE_TOGGLE";
export const CANDIDATE_ROUND_CHANGE_TOGGLE = "CANDIDATE_ROUND_CHANGE_TOGGLE";
export const FEEDBACK_CANDIDATE_INPUT = "FEEDBACK_CANDIDATE_INPUT";
export const CANDIDATE_JOB_CHANGE_LOADER = "CANDIDATE_JOB_CHANGE_LOADER";
export const SET_FEEDBACK_VIEW = "SET_FEEDBACK_VIEW";
export const SET_CANDIDATE_SCREENSHOT = "SET_CANDIDATE_SCREENSHOT";
export const TOOGLE_FEEDBACK_LOADER = "TOOGLE_FEEDBACK_LOADER";
export const SET_CANDIDATE_FEEDBACK = "SET_CANDIDATE_FEEDBACK";
export const SELECT_CANDIDATE_ROUND = "SELECT_CANDIDATE_ROUND";
export const SET_SELECTED_CANDIDATE = "SET_SELECTED_CANDIDATE";

//Feedback code starts

export const FEEDBACK_DETAILS_GET = "FEEDBACK_DETAILS_GET";

export const FEEDBACK_PAGE_LOADER = "FEEDBACK_PAGE_LOADER";
export const FEEDBACK_ERROR_PAGE = "FEEDBACK_ERROR_PAGE";
export const FEEDBACK_SUBMIT_LOADER = "FEEDBACK_SUBMIT_LOADER";
export const FEEDBACK_SUBMITTED = "FEEDBACK_SUBMITTED";
export const FEEDBACK_SCROLL_POSITION = "FEEDBACK_SCROLL_POSITION";
export const FEEDBACK_CANDIDATE_ID = "FEEDBACK_CANDIDATE_ID";
export const FEEDBACK_CANDIDATE_RESET = "FEEDBACK_CANDIDATE_RESET";
export const FEEDBACK_CANDIDATE_INFO_RESET = "FEEDBACK_CANDIDATE_INFO_RESET";

export const FEEDBACK_RECENT_PHOTO = "FEEDBACK_RECENT_PHOTO";
export const FEEDBACK_RECENT_PHOTO_URL = "FEEDBACK_RECENT_PHOTO_URL";

export const HR_FEEDBACK_MODAL = "HR_FEEDBACK_MODAL";
export const ADD_PREVIOUS_ORG_MODAL = "ADD_PREVIOUS_ORG_MODAL";
export const NOTICE_PERIOD_ORDER = "NOTICE_PERIOD_ORDER";
export const FILTERED_STATUS_ONGOING = "FILTERED_STATUS_ONGOING"; //filterStatus Ongoing
export const GET_CANDIDATE_URL = "GET_CANDIDATE_URL";
export const GET_CANDIDATE_PARAMS = "GET_CANDIDATE_PARAMS";
export const SHOW_DOWNLOAD_CANDIDATE_MODEL = "SHOW_DOWNLOAD_CANDIDATE_MODEL";

export const getCandidateFormat = {
  name: "",
  interviewSlots: [
    {
      date: null,
      round: null,
      interviewMode: "",
    },
  ],
  feedbackRecentPhoto: [],
};

export const dateFormat = "DD-MM-YYYY";
export const postDateFormat = "YYYY/MM/DD";

export const resetFilledDetails = (dispatch) => {
  dispatch(performOperation(FEEDBACK_SCROLL_POSITION, 0));
  dispatch(updateInputField(FEEDBACK_CANDIDATE_INFO_RESET));
  dispatch(performOperation(FEEDBACK_SUBMITTED, false));
  dispatch(updateInputField(FEEDBACK_CANDIDATE_RESET));
  dispatch(performOperation(FEEDBACK_ERROR_PAGE, false));
  dispatch(performOperation(FEEDBACK_SCROLL_POSITION, 0));
};

export const getRecruitmentFeedback = () => {
  return (dispatch, getState) => {
    const { candidateInfo } = getState().candidate;
    resetFilledDetails(dispatch);
    dispatch(performOperation(FEEDBACK_PAGE_LOADER, true));
    return Api.get(`/hiring-feedback/${candidateInfo.id}`).send((response, error) => {
      if (response !== undefined) {
        dispatch(feedbackDetailsGet(response));
      } else {
        dispatch(performOperation(FEEDBACK_ERROR_PAGE, true));
      }
      dispatch(performOperation(FEEDBACK_PAGE_LOADER, false));
    });
  };
};
export const getJobsForDropdownInJd = () => {
   Api.get("/hiring-job/dropdown"). params({jobType:"Experienced"}).send((response, error) => {
      if (response !== undefined) {
        store?.dispatch(updateCandidateJobs(response));       
        let vacCount = 0;
        response.map((item) => (vacCount += item.vacancies));
        store?.dispatch(
          updateInputField(inputFieldType.EXPERIENCED_VACANCIES, vacCount)
        );
        store?.dispatch(
          updateInputField(inputFieldType.TOTAL_EXPERIENCED_VACANCIES, vacCount)
        );
      }})
    
};
// Functions for candidate job filter
export const getJobsForDropdown = () => {
  return (dispatch, getState) => {
    const { jobStatus } = getState().candidate;
    return Api.get("/hiring-job/dropdown"). params({jobType:"Experienced"}).send((response, error) => {
      if (response !== undefined) {
        dispatch(updateCandidateJobs(response));
        let vacCount = 0;
        response.map((item) =>{
          if(item?.status === "Active" && jobStatus === "Active"){
            (vacCount += item.vacancies)
          }
          else if(item?.status === "Inactive" && jobStatus === "Inactive"){
            (vacCount += item.vacancies)
          }
        })
        dispatch(
          updateInputField(inputFieldType.EXPERIENCED_VACANCIES, vacCount)
        );
        dispatch(
          updateInputField(inputFieldType.TOTAL_EXPERIENCED_VACANCIES, vacCount)
        );
      }
    });
  };
};

export const candidateJobFilter = (jobFilter) => ({
  type: CANDIDATE_JOB_FILTER,
  jobFilter,
});

// const {basicInfo} = useSelector(state => state.HrFeedback)
export const postRecruitmentFeedback = () => {
  return (dispatch, getState) => {
    const {
      feedbackCandidate,
    
      history,
      feedbackCandidateInfo,
      feedbackJobInterviewer,
      feedbackQuestions,
      candidateInfo,
    } = getState().candidate;

    const { basicInfo } = getState().HrFeedback;
    const { feedback } = getState().candidate.feedbackCandidateInfo;
    let feedbackType = feedback.find((value) => value.roundType === "HR");

    const params = {
      details: JSON.stringify(basicInfo),
      candidateName: feedbackCandidateInfo.name,
      round: feedbackJobInterviewer.round,
      roundType: feedbackJobInterviewer.roundType,
      interviewer: feedbackJobInterviewer.panelist.name,
      interviewDate: feedbackCandidateInfo.interviewSlots[0].date,
      interviewMode: feedbackCandidateInfo.interviewSlots[0].interviewMode,
      overallRating: basicInfo.OverallRating,
      overallComment: basicInfo.comments,
      status: basicInfo.candidateresult,
      jobId: feedbackCandidateInfo.jobs.id,
      panelistId: feedbackJobInterviewer.panelist.id,
    };
    let ScreenPhoto;
    if (
      feedbackCandidateInfo.feedbackRecentPhoto?.length === 0 &&
      !feedbackType?.screenshot
    ) {
      ScreenPhoto = null;
    } else if (
      !feedbackCandidateInfo?.feedbackRecentPhoto &&
      feedbackType?.screenshot
    ) {
      ScreenPhoto = feedbackType?.screenshot;
    } else {
      ScreenPhoto = feedbackCandidateInfo.feedbackRecentPhoto[0];
    }
    const postJson = {
      ...params,
      screenshot: ScreenPhoto,
    };
    // const error = Object.entries(feedbackQuestions.questions).reduce(
    //   (err, [key, value]) => {
    //     if (value.required) {
    //       if (
    //         Array.isArray(value.key) &&
    //         (feedbackCandidate[value.key[0]] === null ||
    //           feedbackCandidate[value.key[0]] === "" ||
    //           feedbackCandidate[value.key[1]] === null ||
    //           feedbackCandidate[value.key[1]] === "")
    //       )
    //         return true;
    //       else if (
    //         feedbackCandidate[value.key] === 0 ||
    //         feedbackCandidate[value.key] === ""
    //       )
    //         return true;
    //       else return err;
    //     } else return err;
    //   },
    //   false
    // );

    // if (error) {
    //   Notification.error("error", "Please fill all the required fields");
    // } else {
    dispatch(performOperation(FEEDBACK_SUBMIT_LOADER, true));
    return Api.post(`/hiring-feedback/${candidateInfo.id}`)
      .params(postJson)
      .uploadAndPost(postJson, (percent, response) => {
        if (
          response !== undefined &&
          response?.data?.show?.type === "success"
        ) {
          dispatch(performOperation(FEEDBACK_SUBMITTED, true));
          dispatch(updateInputField(FEEDBACK_CANDIDATE_RESET));
          dispatch(updateInputField(FEEDBACK_CANDIDATE_INFO_RESET));
          dispatch(performOperation(operationType.HR_FEEDBACK_MODAL, false));
          dispatch(getAllCandidates(history));
          dispatch(HrFeedbackActions.removeFeedback());
        }
        setTimeout(() => {
          dispatch(performOperation(FEEDBACK_SUBMIT_LOADER, false));
        }, 2000);
      });
    // }
  };
};

export const calculateCompletion = () => {
  return (dispatch, getState) => {
    const { feedbackCandidate, feedbackCount, feedbackQuestions } =
      getState().candidate;
    const filledCount = Object.entries(feedbackQuestions.questions).reduce(
      (count, [key, value]) => {
        if (value.required) {
          if (
            Array.isArray(value.key) &&
            (feedbackCandidate[value.key[0]] === null ||
              feedbackCandidate[value.key[0]] === "" ||
              feedbackCandidate[value.key[1]] === null ||
              feedbackCandidate[value.key[1]] === "")
          )
            return count;
          else if (
            feedbackCandidate[value.key] === 0 ||
            feedbackCandidate[value.key] === ""
          )
            return count;
          else return count + 1;
        } else return count;
      },
      0
    );
    dispatch(
      performOperation(
        FEEDBACK_SCROLL_POSITION,
        parseInt(filledCount * (100 / feedbackCount))
      )
    );
  };
};

//Feedback code ends

// export const candidateAgencyFormat = []
// export const candidateAgencyFormat = {
//   id: -1,
//   agencyName: "All",
// };
export const candidateAgencyFormat = "";

const slotFormat = {
  id: null,
  date: "",
  panelist: {
    id: null,
    name: "",
    email: "",
  },
  schedule: {
    id: null,
    startTime: "",
    endTime: "",
  },
};

//This is candidate's panelist info before updation
const panelistFormat = {
  id: null,
  name: "",
  email: "",
  phone: "",
  designation: "",
};

export const candidateFormat = {
  id: null,
  name: "",
  currentLocation: "",
  email: "",
  phone: "",
  skypeId: "",
  reference: "",
  experienceYears: null,
  experienceMonths: null,
  noticePeriod: null,
  preferredLocation: "",
  currentCtc: "",
  expectedCtc: "",
  currentCompany: "",
  resume: "",
  candidatePhoto: "",
  duplicate: null,
  blacklist: false,
  reference: null,
  candidateStatus: "",
  currentRound: null,
  interviewSlots: [slotFormat],
  panelist: panelistFormat,
};

// export const getCandidateHistory = () =>
// multiple job and agency selection
// export const handleCandidateJobChange = () => {

// }

export const selectCandidateRound = (val) => ({
  type: SELECT_CANDIDATE_ROUND,
  val,
});

export const setFeedbackView = (bool) => ({
  type: SET_FEEDBACK_VIEW,
  bool,
});

export const setCandidateScreenshot = (value) => ({
  type: SET_CANDIDATE_SCREENSHOT,
  value,
});

export const updateCandidateJobs = (jobs) => ({
  type: UPDATE_CANDIDATE_JOBS,
  jobs,
});

export const getCandidateFeedback = () => {
  return (dispatch, getState) => {
    const candidateId = getState().candidate.selectedCandidate.id;
    dispatch(fetchFeedbackLoader(true));
    return Api.get(`/hiring-recruitment/feedback/${candidateId}`).send(
      (response, error) => {
        if (response !== undefined) {
        }
        dispatch(fetchFeedbackLoader(false));
        dispatch(setCandidateFeedback(response));
      }
    );
  };
};

export const fetchFeedbackLoader = (bool) => ({
  type: TOOGLE_FEEDBACK_LOADER,
  bool,
});

const setCandidateFeedback = (data) => {
  if(data){
    return{
      type: SET_CANDIDATE_FEEDBACK,
      data,
      }
  }
 
};

export const setCurrenCandidate = (data) => ({
  type: SET_SELECTED_CANDIDATE,
  data,
});

export const handleSkipRound = (id, status) => {
  return (dispatch) => {
    if (status === "Selected") {
      return;
    }
    return Api.patch(`/hiring-recruitment/skipround/${id}`).send((response, error) => {
      dispatch(performOperation(CANDIDATE_TABLE_LOADER, true));
      if (response !== undefined) {
        dispatch(candidateSkipRound(id, response.params));
      }
      dispatch(performOperation(CANDIDATE_TABLE_LOADER, false));
    });
  };
};

export const handleSkipCurrentRound = (id, status) => {
  return (dispatch) => {
    if (status === "Selected") {
      return;
    }
    return Api.patch(`/hiring-recruitment/skipcurrentRound/${id}`).send((response, error) => {
      dispatch(performOperation(CANDIDATE_TABLE_LOADER, true));
      if (response !== undefined) {
        dispatch(candidateSkipRound(id, response.params));
      }
      dispatch(performOperation(CANDIDATE_TABLE_LOADER, false));
    });
  };
};

export const cancelRound = (id,reason) => {
  return (dispatch) => {

    return Api.delete(`/hiring-recruitment/bookslot/${id}`)
              .params({reason})
             .send((response, error) => {
              dispatch(performOperation(CANDIDATE_TABLE_LOADER, true));
              if (response !== undefined) {

              }
              dispatch(performOperation(CANDIDATE_TABLE_LOADER, false));
       });
  };
};

export const getAllCandidates = (history, page, limit) => {
  if (!page) {
    page = 1;
  }
  if (!limit) {
    limit = 25;
  }

  return (dispatch, getState) => {
    const { searchValue } = getState().createUser;

    const { user } = getState().session;
    const {
      candidateAgency,
      candidateRound,
      candidateDateRange,
      candidateFilterStatus,
      candidateFilterHackathonStatus,
      candidateScreenType,
      duplicateCandFilterStatus,
      candidateAgencyForHistory,
      candidateJobDescriptionForHistory,
      candidateJobDescription,
      noticePeriodOrder,
      filterStatusOngoing,
      candidateJobFilter,
      candidateExperienceMax,
      candidateExperienceMin,
      candidateTableSearch,
      shortlisterComment,
    } = getState().candidate;
    console.log("filteredStatusGetting",filterStatusOngoing);
    
    let candidateJDFH = candidateJobDescriptionForHistory?.slice();
   
    let candidateAFH = candidateAgencyForHistory.slice();
    const agencyQuery =
      candidateAgency !== undefined && candidateAgency !== -1
        ? `&agencyId=${candidateAgency}`
        : "";
    const jobQuery =
     candidateJobDescription.length !== 0 ||candidateJobDescription !== []
        ? `&jobId=${JSON.stringify(candidateJobDescription)}`
        : ""

    const agencyQueryHistoryTab = candidateAFH.length
      ? `&agencyId=[${candidateAFH}]`
      : "";
    const jobQueryHistoryTab = !candidateJDFH.length
      ? ""
      : `&jobId=[${candidateJDFH}]`;
    const roundQuery =
      candidateRound !== undefined &&
      candidateRound !== "All" &&
      candidateRound !== ""
        ? `&round=${candidateRound}`
        : "";
    const maximumYear =
      candidateExperienceMax !== undefined &&
      candidateExperienceMax !== "All" &&
      candidateExperienceMax !== ""
        ? `&maxYear=${candidateExperienceMax}`
        : "";
    const minimumYear =
      candidateExperienceMin !== undefined &&
      candidateExperienceMin !== "All" &&
      candidateExperienceMin !== ""
        ? `&minYear=${candidateExperienceMin}`
        : "";
      
    const dateRangeQuery = candidateDateRange
      ? `&dateRange=${candidateDateRange[0]}/${candidateDateRange[1]}`
      : "";
    const statusQuery =
      candidateFilterStatus !== undefined && candidateFilterStatus !== "All"
        ? `&status=${candidateFilterStatus}`
        : candidateScreenType === "Ongoing"
        ? `&status=Ongoing`
        : `&status=Rejected`;
    const hackathonStatusQuery =
      // candidateFilterHackathonStatus !== undefined && candidateFilterHackathonStatus !== "All"
          `&hackathonStatus=${candidateFilterHackathonStatus}`
          // : candidateScreenType === "Ongoing"
          // ? `&status=Ongoing`
          // : `&status=Rejected`;
    const statusQueryHistoryTab =
      candidateFilterStatus !== undefined && candidateFilterStatus !== "All"
        ? `&status=${candidateFilterStatus}`
        : "";
    const pageQuery = `&page=${parseInt(page)}`;
    const shortListerCommentQuery=!shortlisterComment?"":`&shortlisterComment=${shortlisterComment}`
    const searchQuery =
      !candidateTableSearch || candidateTableSearch === null
        ? ""
        : `&search=${candidateTableSearch}`;
    const noticePeriodQuery =
      !noticePeriodOrder || noticePeriodOrder == "null"
        ? ""
        : `&noticePeriodOrder=${noticePeriodOrder}`;   
    const mainUrl = user.role?.includes("Agency")
      ? "/hiring-candidate/agency"
      : "/hiring-candidate/expCandidates";
    const url =
      candidateScreenType === "New"
        ? `${mainUrl}?${searchQuery}${jobQuery}${agencyQuery}${minimumYear}${maximumYear}${roundQuery}${dateRangeQuery}${hackathonStatusQuery}&status=New${pageQuery}&limit=${limit}`
        : `${mainUrl}?${searchQuery}${jobQuery}${agencyQuery}${minimumYear}${maximumYear}${roundQuery}${dateRangeQuery}${statusQuery}${hackathonStatusQuery}${noticePeriodQuery}${pageQuery}&limit=${limit}${shortListerCommentQuery}`;

    let browserURL = new URL(window.location.href);
    dispatch(candidateCommentToggle(false, null));
    if (user.role?.includes("Agency") && browserURL.pathname === "/candidate") {
      history.push("/agency-candidate");
    } else {
      dispatch(performOperation(CANDIDATE_TABLE_LOADER, true));

      let urlFinal = "";
      let params = { }
      
      if (candidateScreenType === "Duplicate") {
        // urlFinal = `/hiring-candidate/history?${searchQuery}${jobQuery}${minimumYear}${maximumYear}${agencyQueryHistoryTab}${roundQuery}${dateRangeQuery}&status=Duplicate${pageQuery}&limit=${limit}&candStatus=${duplicateCandFilterStatus}${shortListerCommentQuery}`;
           urlFinal = `/hiring-candidate/history`  
           params = { ...params, status:'Duplicate', candStatus:duplicateCandFilterStatus, page: parseInt(page), limit: limit, };
           if(candidateTableSearch || candidateTableSearch !== null || candidateTableSearch !== undefined){
            params = { ...params, search: candidateTableSearch, };
          }
          
          if(candidateFilterHackathonStatus !== undefined && candidateFilterHackathonStatus !== "All"){
            params = { ...params, hackathonStatus : candidateFilterHackathonStatus, };
          }
          if(candidateJobDescription.length !== 0 || candidateJobDescription !== []){
            params = { ...params, jobId: `${JSON.stringify(candidateJobDescription)}` };
          }
          if(candidateExperienceMin !== undefined && candidateExperienceMin !== "All" &&  candidateExperienceMin !== ""){
            params = { ...params, minYear: candidateExperienceMin, };
          }
          if(candidateExperienceMax !== undefined && candidateExperienceMax !== "All" && candidateExperienceMax !== ""){
            params = { ...params,maxYear: candidateExperienceMax, };
          }
          if( candidateAFH.length){
            params = { ...params, agencyId: `[${candidateAFH}]` };
          }
          if(candidateRound !== undefined && candidateRound !== "All" && candidateRound !== ""){
            params = { ...params, round : candidateRound, };
          }
          if(candidateDateRange){
            params = { ...params, dateRange: `${candidateDateRange[0]}/${candidateDateRange[1]}` };
          }
          if(shortlisterComment || shortlisterComment !== "null" || shortlisterComment !== undefined){
            params = { ...params, shortlisterComment: shortlisterComment, };
          }
      } else if (candidateScreenType === "History") {
        // urlFinal = `/hiring-candidate/history?${searchQuery}${jobQuery}${minimumYear}${maximumYear}${agencyQueryHistoryTab}${roundQuery}${dateRangeQuery}${statusQueryHistoryTab}${pageQuery}&limit=${limit}${shortListerCommentQuery}`;
        urlFinal = `/hiring-candidate/history`   
        params = { ...params, page: parseInt(page), limit:limit, };
        if(candidateFilterStatus !== undefined && candidateFilterStatus !== "All"){
          params = { ...params, status : candidateFilterStatus, };
        }
        if(candidateFilterHackathonStatus !== undefined && candidateFilterHackathonStatus !== "All"){
          params = { ...params, hackathonStatus : candidateFilterHackathonStatus, };
        }  
        if(candidateTableSearch || candidateTableSearch !== null || candidateTableSearch !== undefined){
          params = { ...params, search: candidateTableSearch, };
        }
        if(candidateJobDescription.length !== 0 || candidateJobDescription !== []){
          params = { ...params, jobId: `${JSON.stringify(candidateJobDescription)}` };
        }
        if(candidateExperienceMin !== undefined && candidateExperienceMin !== "All" &&  candidateExperienceMin !== ""){
          params = { ...params, minYear: candidateExperienceMin, };
        }
        if(candidateExperienceMax !== undefined && candidateExperienceMax !== "All" && candidateExperienceMax !== ""){
          params = { ...params,maxYear: candidateExperienceMax, };
        }
        if( candidateAFH.length){
          params = { ...params, agencyId: `[${candidateAFH}]` };
        }
        if(candidateRound !== undefined && candidateRound !== "All" && candidateRound !== ""){
          params = { ...params, round : candidateRound, };
        }
        if(candidateDateRange){
          params = { ...params, dateRange: `${candidateDateRange[0]}/${candidateDateRange[1]}` };
        }
        if(shortlisterComment || shortlisterComment !== "null"){
          params = { ...params, shortlisterComment: shortlisterComment, };
        }
      } else if (candidateScreenType === "jdChangeProfile") {
        // urlFinal = `/hiring-candidate/expCandidates?status=${"ProposedJd"}${searchQuery}${shortListerCommentQuery}`;
        urlFinal = `/hiring-candidate/expCandidates`
        params = { ...params, status: "ProposedJd" };
        if(candidateTableSearch || candidateTableSearch !== null || candidateTableSearch !== undefined){
          params = { ...params, search: candidateTableSearch, };
        }
        if(shortlisterComment || shortlisterComment !== "null" || shortlisterComment !== undefined){
          params = { ...params, shortlisterComment: shortlisterComment, };
        }
      } 
      else {
        if(candidateScreenType === "New"){
          urlFinal = "/hiring-candidate/expCandidates";
          params = { ...params, status:'New', page: parseInt(page), limit: limit, };
          if(candidateTableSearch || candidateTableSearch !== null || candidateTableSearch !== undefined){
            params = { ...params, search: candidateTableSearch, };
          }
          if(candidateFilterHackathonStatus !== undefined && candidateFilterHackathonStatus !== "All"){
            params = { ...params, hackathonStatus : candidateFilterHackathonStatus, };
          }
          if(candidateJobDescription.length !== 0 || candidateJobDescription !== []){
            params = { ...params, jobId: `${JSON.stringify(candidateJobDescription)}` };
          }
          if(candidateAgency !== undefined && candidateAgency !== -1){
            params = { ...params, agencyId: candidateAgency };
          }
          if(candidateExperienceMin !== undefined && candidateExperienceMin !== "All" &&  candidateExperienceMin !== ""){
            params = { ...params, minYear: candidateExperienceMin, };
          }
          if(candidateExperienceMax !== undefined && candidateExperienceMax !== "All" && candidateExperienceMax !== ""){
            params = { ...params,maxYear: candidateExperienceMax, };
          }
          if(candidateRound !== undefined && candidateRound !== "All" && candidateRound !== ""){
            params = { ...params, round : candidateRound, };
          }
          if(candidateDateRange){
            params = { ...params, dateRange: `${candidateDateRange[0]}/${candidateDateRange[1]}` };
          }
        }
        else {
          // urlFinal = `${mainUrl}?${searchQuery}${jobQuery}${agencyQuery}${minimumYear}${maximumYear}${roundQuery}${dateRangeQuery}${statusQuery}${noticePeriodQuery}${pageQuery}&limit=${limit}${shortListerCommentQuery}`;
          urlFinal = "/hiring-candidate/expCandidates";
          params = { ...params, page: parseInt(page), limit: limit, status : candidateScreenType === "Ongoing" ?  "Ongoing" : "Rejected"}
          if(candidateFilterStatus !== undefined && candidateFilterStatus !== "All"){
            params = { ...params, status : candidateFilterStatus, };
          }
          if(candidateTableSearch || candidateTableSearch !== null || candidateTableSearch !== undefined){
            params = { ...params, search: candidateTableSearch, };
          }
          if(candidateJobDescription.length !== 0 || candidateJobDescription !== []){
            params = { ...params, jobId: `${JSON.stringify(candidateJobDescription)}` };
          }
          if(candidateAgency !== undefined && candidateAgency !== -1){
            params = { ...params, agencyId: candidateAgency };
          }
          if(candidateExperienceMin !== undefined && candidateExperienceMin !== "All" &&  candidateExperienceMin !== ""){
            params = { ...params, minYear: candidateExperienceMin, };
          }
          if(candidateExperienceMax !== undefined && candidateExperienceMax !== "All" && candidateExperienceMax !== ""){
            params = { ...params,maxYear: candidateExperienceMax, };
          }
          if(candidateRound !== undefined && candidateRound !== "All" && candidateRound !== ""){
            params = { ...params, round : candidateRound, };
          }
          if(candidateDateRange){
            params = { ...params, dateRange: `${candidateDateRange[0]}/${candidateDateRange[1]}` };
          }
          if(noticePeriodOrder || noticePeriodOrder !== "null"){
            params = { ...params, noticePeriodOrder: noticePeriodOrder  };
          }
          if(shortlisterComment || shortlisterComment !== "null" || shortlisterComment !== undefined){
            params = { ...params, shortlisterComment: shortlisterComment, };
          }
          if(candidateFilterHackathonStatus !== undefined && candidateFilterHackathonStatus !== "All"){
            params = { ...params, hackathonStatus : candidateFilterHackathonStatus, };
          } 
          if(filterStatusOngoing.length>0 &&!filterStatusOngoing.includes("All")){
            params={...params,filteredStatus:filterStatusOngoing}
          }
          // else if(candidateScreenType === "Ongoing"){
          //   params = { ...params, status : "Ongoing", };
          // }
          // else{
          //   params = { ...params, status : "Rejected", };
          // }
        }
      }
      dispatch(performOperation(GET_CANDIDATE_URL, urlFinal));
      dispatch(performOperation(GET_CANDIDATE_PARAMS, params));
      return Api.post(urlFinal).params(params).send((response, error) => {
        if (response !== undefined) {
          dispatch(candidateDetailsGet(response));
        }
        dispatch(performOperation(SEARCH_VALUE, ""));
        dispatch(performOperation(CANDIDATE_TABLE_LOADER, false));
      });
    }
  };
};

export const getDownloadPagination = () => {
  return (dispatch, getState) => {
    const { candidatePageMeta } = getState().candidate;
    const pages = Math.floor(candidatePageMeta.totalItems / 500) + 1;
    dispatch(performOperation(SHOW_DOWNLOAD_CANDIDATE_MODEL, true));
  };
};

export const getCandidatesOnTabChange = (history) => {
  return (dispatch) => {
    dispatch(updateInputField(CANDIDATE_CLEAR, []));
    dispatch(updateInputField(CANDIDATE_DATE_RANGE, null));
    dispatch(updateInputField(CANDIDATE_FILTER_STATUS, "All"));
    dispatch(updateInputField(FILTERED_STATUS_ONGOING, ["All"]));
    dispatch(performOperation(operationType.DUPLICATE_CANDIDATE_STATUS, ""))
    dispatch(updateInputField(CANDIDATE_FILTER_HACKATHON_STATUS, "All"));
    dispatch(getAllCandidates(history));
  };
};

export const getDuplicateCandidates = () => {
  return (dispatch, getState) => {
    const { candidateInfo } = getState().candidate;
    dispatch(performOperation(operationType.DUPLICATE_VIEW_MODAL, true));
    return Api.get(`/hiring-candidate/duplicate/${candidateInfo.id}`).send(
      (response, error) => {
        if (response !== undefined) {
          dispatch(duplicateCandidateGet(response));
        }
      }
    );
  };
};

export const statusDropdownAction = (history) => {
  return (dispatch, getState) => {
    const { candidateStatusDropdown } = getState().candidate;
    dispatch(
      updateInputField(
        inputFieldType.CANDIDATE_STATUS_DROPDOWN,
        !candidateStatusDropdown
      )
    );
    dispatch(getAllCandidates(history));
  };
};

export const hackathonStatusDropdownAction = (history) => {
  return (dispatch, getState) => {
    const { candidateHackathonStatusDropdown } = getState().candidate;
    dispatch(
      updateInputField(
        inputFieldType.CANDIDATE_HACKATHON_STATUS_DROPDOWN,
        !candidateHackathonStatusDropdown
      )
    );
    dispatch(getAllCandidates(history));
  };
};

export const handleCandidateEdit = () => {
  return (dispatch, getState) => {
    const { candidateInfo, candidateJobDescription } = getState().candidate;
    dispatch(performOperation(CANDIDATE_VIEW_MODAL, false));
    const postJson = { ...candidateInfo, jobId: candidateJobDescription.id };
    if (!candidateJobDescription.id) {
      Notification.error("error", "please select job title");
    } else {
      dispatch(performOperation(CANDIDATE_TABLE_LOADER, true));
      return Api.patch(`/hiring-candidate/${candidateInfo.id}`)
        .params(postJson)
        .send((response, error) => {
          if (response !== undefined) {
            dispatch(candidateDetailsEdit(candidateInfo.id, candidateInfo));
          }
          dispatch(performOperation(CANDIDATE_TABLE_LOADER, false));
        });
    }
  };
};

export const handleCandidatePhotoEdit = () => {
  return (dispatch, getState) => {
    const { candidateInfo, candidateJobDescription, candidatePhotoEdit } =
      getState().candidate;
    const postJson = { candidatePhoto: candidatePhotoEdit };

    dispatch(performOperation(CANDIDATE_PHOTO_LOADER, true));
    return Api.patch(`/hiring-candidate/candidatephoto/${candidateInfo.id}`)
      .params(postJson)
      .uploadAndPatch(postJson, (percent, response) => {
        if (response !== undefined && percent === 100) {
          dispatch(performOperation(CANDIDATE_PHOTO_EDIT_BOOL, false));
          dispatch(
            candidateDetailsEdit(candidateInfo.id, {
              ...candidateInfo,
              candidatePhoto: response.data.candidatePhoto,
            })
          );
        }
        if (percent === 100) {
          dispatch(performOperation(CANDIDATE_PHOTO_LOADER, false));
        }
      });
    //This change is to remove check of select job for photo add or edit.
    // if (!candidateJobDescription.id) {
    //   Notification.error("error", "please select job title");
    // } else {

    // }
  };
};

export const handleCandidateFlagEdit = () => {
  return (dispatch, getState) => {
    const { candidateInfo, candidateJobDescription } = getState().candidate;
    dispatch(performOperation(CANDIDATE_VIEW_MODAL, false));
    const postJson = {
      blacklist: candidateInfo.blacklist,
      duplicate: candidateInfo.duplicate,
    };
    dispatch(performOperation(CANDIDATE_TABLE_LOADER, true));
    return Api.patch(`/hiring-candidate/flag/${candidateInfo.id}`)
      .params(postJson)
      .send((response, error) => {
        if (response !== undefined && response?.show?.type === 'success') {
          dispatch(candidateDetailsEdit(candidateInfo.id, candidateInfo));
        }
        dispatch(performOperation(CANDIDATE_TABLE_LOADER, false));
      });
    //This change is to remove check of select job for blacklist and duplicate flag.
    // if (!candidateJobDescription.id) {
    //   Notification.error("error", "please select job title");
    // } else {

    // }
  };
};

export const handleCandidateStatus = (id, status, comment,candidatePriority) => {
 
  return (dispatch, getState) => {
    const {
      candidateStatus,
      candidateScreenType,
      candidateRejectedStatus,
      candidateOngoingStatus,
    } = getState().candidate;

    const postJson = { status: status, shortlisterComment: comment,candidateType:"Experienced",candidatePriority:candidatePriority};

    const dispatchSpecificLoader = (value) =>
      status === candidateStatus.shortlisted
        ? dispatch(performOperation(CANDIDATE_SHORTLISTED_LOADER, value))
        : status === candidateStatus.notShortlisted
        ? dispatch(performOperation(CANDIDATE_REJECTED_LOADER, value))
        : dispatch(performOperation(CANDIDATE_TABLE_LOADER, value));

    const checkCandidateHide =
      candidateScreenType === "New" ||
      (candidateScreenType === "Ongoing" &&
        !candidateOngoingStatus.includes(status)) ||
      (candidateScreenType === "Rejected" &&
        !candidateRejectedStatus.includes(status))
        ? true
        : false;

    dispatchSpecificLoader(true);
    dispatch(performOperation(CANDIDATE_TABLE_LOADER, true));
    return Api.patch(`/hiring-recruitment/status/${id}`)
      .params(postJson)
      .send((response, error) => {
        if (response !== undefined) {
          dispatch(performOperation(CANDIDATE_VIEW_MODAL, false));
          if (checkCandidateHide&&response?.show?.type!=="error") {
             dispatch(candidateDetailsHide(id));
          } else {
            dispatch(
              candidateRecruitmentEdit(CANDIDATE_RECRUITMENT_STATUS, id, status)
            );
          }
        }
        dispatchSpecificLoader(false);
        dispatch(performOperation(CANDIDATE_TABLE_LOADER, false));
      });
  };
};

export const getInterviewSlots = () => {
  return (dispatch, getState) => {
    const {
      interviewDate,
      candidateJobDescription,
      candidateInfo,
      panelist,
      changedPanelist,
    } = getState().candidate;
    const postInterviewDate = moment(interviewDate, dateFormat).format(
      postDateFormat
    );
    let data = [];
    candidateInfo?.job?.jobInterviewrounds.map((element) => {
      if (element.round === candidateInfo.currentRound + 1) data.push(element);
    });
    const url = `/hiring-recruitment/interview-slot/?candidateId=${
      candidateInfo.id
    }&date=${postInterviewDate}&panelistId=${
      candidateInfo.panelist
        ? changedPanelist
          ? panelist.id
          : candidateInfo.panelist.id
        : ""
    }&jobInterviewRoundId=${data[0]?.id}`;

    dispatch(performOperation(INTERVIEW_ONMODAL_LOADER, true));
    return Api.get(url).send((response, error) => {
      if (response !== undefined) {
        dispatch(updateInputField(INTERVIEW_AVAILABLE_SLOTS, response));
        dispatch(
          updateInputField(INTERVIEW_SELF_BOOKED_SLOTS, response.selfBookedSlot)
        );
        dispatch(
          updateInputField(INTERVIEW_PANELIST_NAME, response.panelistName)
        );
      }
      dispatch(performOperation(INTERVIEW_ONMODAL_LOADER, false));
    });

    // if (!candidateJobDescription.id) {
    //   Notification.error("error", "please select job title");
    // } else {

    // }
  };
};

export const scheduleInterview = () => {
  return (dispatch, getState) => {
    const {
      interviewSchedule,
      interviewDate,
      candidateInfo,
      candidateStatus,
      interviewType,
      history,
    } = getState().candidate;
    const postInterviewDate = moment(interviewDate, dateFormat).format(
      postDateFormat
    );
    const params = {
      interviewMode: interviewType,
      date: postInterviewDate,
      candidateId: candidateInfo.id,
      scheduleId: interviewSchedule.id,
      currentRound: candidateInfo.currentRound,
    };

    dispatch(performOperation(INTERVIEW_SUBMIT_LOADER, true));
    return Api.post("/hiring-recruitment/bookslot")
      .params(params)
      .send((response, error) => {
        if (response !== undefined && response.interviewSlots !== undefined) {
          dispatch(
            candidateRecruitmentEdit(
              CANDIDATE_RECRUITMENT_STATUS,
              candidateInfo.id,
              candidateStatus.scheduled
            )
          );
          dispatch(
            candidateRecruitmentEdit(
              CANDIDATE_RECRUITMENT_ROUND,
              candidateInfo.id,
              candidateInfo.currentRound + 1
            )
          );
          dispatch(
            interviewSlotsAdd(candidateInfo.id, response.interviewSlots)
          );
          dispatch(panelistNameChanged(candidateInfo.id, response.panelist));
          dispatch(getAllCandidates(history));
          dispatch(closeInterviewModal());
        }
        dispatch(performOperation(INTERVIEW_SUBMIT_LOADER, false));
      });
  };
};

// export const deleteScheduledInterview = (values) => {
//   return (dispatch, getState) => {
//     const { candidateInfo, candidateStatus } = getState().candidate;
//     const status =
//       candidateInfo.currentRound === 1
//         ? candidateStatus.shortlisted
//         : candidateStatus.inprogress;
//     const interviewSlotId = candidateInfo.interviewSlots[0].id;

//     dispatch(performOperation(INTERVIEW_DELETE_LOADER, true));
//     return Api.delete(
//       `/hiring-recruitment/bookslot/${interviewSlotId}/${values}`
//     ).send((response, error) => {
//       if (response !== undefined) {
//         dispatch(
//           candidateRecruitmentEdit(
//             CANDIDATE_RECRUITMENT_STATUS,
//             candidateInfo.id,
//             status
//           )
//         );
//         dispatch(
//           candidateRecruitmentEdit(
//             CANDIDATE_RECRUITMENT_ROUND,
//             candidateInfo.id,
//             candidateInfo.currentRound - 1
//           )
//         );
//         dispatch(interviewSlotsDelete(candidateInfo.id));
//       }
//       dispatch(performOperation(INTERVIEW_DELETE_LOADER, false));
//     });
//   };
// };

export const candidateCommentEdit = (id, text, history) => {
  return (dispatch, getState) => {
    dispatch(candidateCommentLoader(true));
    return Api.patch(`/hiring-candidate/comment/${id}`)
      .params({ comment: text })
      .send((response, error) => {
        dispatch(candidateCommentLoader(false));
        if (response !== undefined) {
          dispatch(candidateCommentRead(""));
          dispatch(candidateCommentToggle(false, null));
          dispatch(candidateCommentUpdateLocal(id, text));
        }
      });
  };
};
export const candidateCommentToggle = (id, text) => ({
  type: CANDIDATE_COMMENT_TOGGLE,
  id,
  text,
});
export const candidateCommentRead = (input) => ({
  type: CANDIDATE_COMMENT_READ,
  input,
});
export const candidateCommentLoader = (bool) => ({
  type: CANDIDATE_COMMENT_LOADER,
  bool,
});
export const candidateCommentUpdateLocal = (id, text) => ({
  type: CANDIDATE_COMMENT_UPDATE_LOCAL,
  id,
  text,
});
export const patchCandidateJobChange = () => {
  return (dispatch, getState) => {
    const {
      candidateJobChange,
      candidateInfo,
      candidateOriginalInfo,
      history,
      candidateRoundChange,
    } = getState().candidate;
    dispatch(candidateJobChangeLoader(true));
    return Api.patch(
      `/hiring-recruitment/changejob/${candidateInfo.id}/?jobId=${
        candidateJobChange.id
      }&reset=${candidateRoundChange.title === "Round 0" ? true : false}`
    ).send((response, error) => {
      dispatch(candidateJobChangeLoader(false));
      if (response !== undefined) {
        dispatch(candidateRoundChangeToggle(false, {}));
        dispatch(performOperation(CANDIDATE_JOB_CHANGE_MODAL, false));
        dispatch(candidateJobChangeToggle(false, {}));
        dispatch(performOperation(CANDIDATE_VIEW_MODAL, false));
        dispatch(candidateDetailsHide(candidateOriginalInfo.id));
        const isHrProcess = window.location.pathname == "/hr-process";
        if (isHrProcess) {
          dispatch(getAllHrProcessCandidates(history));
        } else {
          dispatch(getAllCandidates(history));
        }
      }
    });
  };
};

export const candidateJobChangeToggle = (bool, obj) => ({
  type: CANDIDATE_JOB_CHANGE_TOGGLE,
  bool,
  obj,
});

export const candidateRoundChangeToggle = (bool, obj) => ({
  type: CANDIDATE_ROUND_CHANGE_TOGGLE,
  bool,
  obj,
});
export const candidateJobChangeLoader = (bool) => ({
  type: CANDIDATE_JOB_CHANGE_LOADER,
  bool,
});

export const closeInterviewModal = () => {
  return (dispatch) => {
    dispatch(performOperation(INTERVIEW_MODAL, false));
    dispatch(performOperation(INTERVIEW_CONFIRM_MODAL, false));
    dispatch(updateInputField(INTERVIEW_TYPE, "Offline"));
    dispatch(updateInputField(INTERVIEW_SCHEDULE, ""));
    dispatch(updateInputField(INTERVIEW_DATE, null));
    dispatch(updateInputField(INTERVIEW_AVAILABLE_SLOTS, []));
    dispatch(updateInputField(INTERVIEW_SELF_BOOKED_SLOTS, []));
    dispatch(updateInputField(INTERVIEW_PANELIST_NAME, ""));
  };
};

export const candidateSkipRound = (id, value) => ({
  type: CANDIDATE_SKIP_ROUND,
  id,
  value,
});


export const candidateDetailsGet = (value) => ({
  type: CANDIDATE_DETAILS_GET,
  value,
});

export const duplicateCandidateGet = (value) => ({
  type: DUPLICATE_CANDIDATE_GET,
  value,
});

export const candidateDetailsEdit = (id, candidate) => ({
  type: CANDIDATE_DETAILS_EDIT,
  id,
  candidate,
});

export const candidateDetailsHide = (id) => ({
  type: CANDIDATE_DETAILS_HIDE,
  id,
});

export const candidateRecruitmentEdit = (kind, id, value) => ({
  type: CANDIDATE_RECRUITMENT_EDIT,
  kind,
  id,
  value,
});

export const candidateFeedbackAdd = (id, value) => ({
  type: CANDIDATE_FEEDBACK,
  id,
  value,
});

export const interviewSlotsDelete = (id) => ({
  type: INTERVIEW_SLOTS_DELETE,
  id,
});

export const interviewSlotsAdd = (id, value) => ({
  type: INTERVIEW_SLOTS_ADD,
  id,
  value,
});

export const panelistNameChanged = (id, value) => ({
  type: PANELIST_NAME_CHANGED,
  id,
  value,
});

export const updateInputField = (inputFieldType, value) => ({
  type: inputFieldType,
  value,
});

export const performOperation = (operationType, value) => ({
  type: operationType,
  value,
});

//Feedback code
export const feedbackDetailsGet = (value) => ({
  type: FEEDBACK_DETAILS_GET,
  value,
});

export const inputFieldType = {
  CANDIDATE_ROUND_CHANGE: CANDIDATE_ROUND_CHANGE,
  CANDIDATE_JOB_CHANGE: CANDIDATE_JOB_CHANGE,
  CANDIDATE_JOB_DESCRIPTION: CANDIDATE_JOB_DESCRIPTION,
  CANDIDATE_JOB_DESCRIPTION_FOR_HISTORY: CANDIDATE_JOB_DESCRIPTION_FOR_HISTORY,
  CANDIDATE_AGENCIES: CANDIDATE_AGENCIES,
  CANDIDATE_AGENCY: CANDIDATE_AGENCY,
  CANDIDATE_AGENCY_FOR_HISTORY: CANDIDATE_AGENCY_FOR_HISTORY,
  INTERVIEW_TYPE: INTERVIEW_TYPE,
  INTERVIEW_DATE: INTERVIEW_DATE,
  INTERVIEW_AVAILABLE_SLOTS: INTERVIEW_AVAILABLE_SLOTS,
  INTERVIEW_PANELIST_NAME: INTERVIEW_PANELIST_NAME,
  INTERVIEW_SELF_BOOKED_SLOTS: INTERVIEW_SELF_BOOKED_SLOTS,
  INTERVIEW_SCHEDULE: INTERVIEW_SCHEDULE,
  CANDIDATE_CLEAR: CANDIDATE_CLEAR,
  CANDIDATE_RESUME: CANDIDATE_RESUME,
  CANDIDATE_IMAGE: CANDIDATE_IMAGE,
  CANDIDATE_DATE_DROPDOWN: CANDIDATE_DATE_DROPDOWN,
  CANDIDATE_DATE_RANGE: CANDIDATE_DATE_RANGE,
  CANDIDATE_STATUS_DROPDOWN: CANDIDATE_STATUS_DROPDOWN,
  CANDIDATE_HACKATHON_STATUS_DROPDOWN: CANDIDATE_HACKATHON_STATUS_DROPDOWN,
  CANDIDATE_FILTER_STATUS: CANDIDATE_FILTER_STATUS,
  FILTERED_STATUS_ONGOING:FILTERED_STATUS_ONGOING,
  CANDIDATE_FILTER_HACKATHON_STATUS: CANDIDATE_FILTER_HACKATHON_STATUS,
  CANDIDATE_ROUND: CANDIDATE_ROUND,
  CANDIDATE_NEW_PAGE_NUMBER: CANDIDATE_NEW_PAGE_NUMBER,
  CANDIDATE_UPDATE_RESUME: CANDIDATE_UPDATE_RESUME,
  CANDIDATE_ONGOING_PAGE_NUMBER: CANDIDATE_ONGOING_PAGE_NUMBER,
  CANDIDATE_REJECTED_PAGE_NUMBER: CANDIDATE_REJECTED_PAGE_NUMBER,
  CANDIDATE_HISTORY_PAGE_NUMBER: CANDIDATE_HISTORY_PAGE_NUMBER,
  CANDIDATE_PHOTO_EDIT: CANDIDATE_PHOTO_EDIT,
  PANELIST: PANELIST,
  CHANGED_PANELIST: CHANGED_PANELIST,

  CANDIDATE_EXPERIENCE_MAX: CANDIDATE_EXPERIENCE_MAX,
  CANDIDATE_EXPERIENCE_MIN: CANDIDATE_EXPERIENCE_MIN,
  EXPERIENCED_VACANCIES: EXPERIENCED_VACANCIES,
  TOTAL_EXPERIENCED_VACANCIES: TOTAL_EXPERIENCED_VACANCIES,
  CANDIDATE_PROFILES: CANDIDATE_PROFILES,
  CANDIDATE_TABLE_SEARCH: CANDIDATE_TABLE_SEARCH,
  CANDIDATE_JOB_STATUS:CANDIDATE_JOB_STATUS,
  CANDIDATE_MAIL_TO: CANDIDATE_MAIL_TO,
  SELECTED_ROW: SELECTED_ROW,
  SHORTLISTER_COMMENT:SHORTLISTER_COMMENT,
};

export const candidateFieldType = {
  CANDIDATE_BLACKLIST: CANDIDATE_BLACKLIST,
  CANDIDATE_DUPLICATE: CANDIDATE_DUPLICATE,
  CANDIDATE_RECRUITMENT_STATUS: CANDIDATE_RECRUITMENT_STATUS,
  CANDIDATE_RECRUITMENT_ROUND: CANDIDATE_RECRUITMENT_ROUND,
  CANDIDATE_SHORTLISTER_NOTE: CANDIDATE_SHORTLISTER_NOTE,
  CANDIDATE_PRIORITY:CANDIDATE_PRIORITY,
};

export const operationType = {
  CANDIDATE_TABLE_LOADER: CANDIDATE_TABLE_LOADER,
  CANDIDATE_SCREEN_TYPE: CANDIDATE_SCREEN_TYPE,
  DUPLICATE_CANDIDATE_STATUS: DUPLICATE_CANDIDATE_STATUS,
  CANDIDATE_VIEW_MODAL: CANDIDATE_VIEW_MODAL,
  CANCEL_BUTTON: CANCEL_BUTTON,
  RECORD_ID: RECORD_ID,
  REASON_VAL: REASON_VAL,
  OTHER_REASON_VAL: OTHER_REASON_VAL,
  CANDIDATE_JOB_CHANGE_MODAL: CANDIDATE_JOB_CHANGE_MODAL,
  CANDIDATE_SHORTLISTED_LOADER: CANDIDATE_SHORTLISTED_LOADER,
  CANDIDATE_REJECTED_LOADER: CANDIDATE_REJECTED_LOADER,
  INTERVIEW_MODAL: INTERVIEW_MODAL,
  INTERVIEW_SUBMIT_LOADER: INTERVIEW_SUBMIT_LOADER,
  INTERVIEW_ONMODAL_LOADER: INTERVIEW_ONMODAL_LOADER,
  INTERVIEW_DELETE_LOADER: INTERVIEW_DELETE_LOADER,
  INTERVIEW_CONFIRM_MODAL: INTERVIEW_CONFIRM_MODAL,
  CANDIDATE_PDF_VIEW_MODAL: CANDIDATE_PDF_VIEW_MODAL,
  CANDIDATE_IMAGE_VIEW_MODAL: CANDIDATE_IMAGE_VIEW_MODAL,
  DUPLICATE_VIEW_MODAL: DUPLICATE_VIEW_MODAL,
  CANDIDATE_PHOTO_LOADER: CANDIDATE_PHOTO_LOADER,
  CANDIDATE_PHOTO_EDIT_BOOL: CANDIDATE_PHOTO_EDIT_BOOL,
  SHOW_DOWNLOAD_CANDIDATE_MODEL: SHOW_DOWNLOAD_CANDIDATE_MODEL,

  // Candidate View Modal
  CANDIDATE_EMAIL_MODAL: CANDIDATE_EMAIL_MODAL,
  CANDIDATE_EMAIL_SUGGESTIONS: CANDIDATE_EMAIL_SUGGESTIONS,
  EDIT_CANDIDATE_VIEW: EDIT_CANDIDATE_VIEW,
  MAIL_LOADING_UI: MAIL_LOADING_UI,
  ADD_PROFILE_MODAL: ADD_PROFILE_MODAL,

  //Feedback code
  FEEDBACK_ERROR_PAGE: FEEDBACK_ERROR_PAGE,
  FEEDBACK_PAGE_LOADER: FEEDBACK_PAGE_LOADER,
  FEEDBACK_SUBMIT_LOADER: FEEDBACK_SUBMIT_LOADER,
  FEEDBACK_SUBMITTED: FEEDBACK_SUBMITTED,
  FEEDBACK_SCROLL_POSITION: FEEDBACK_SCROLL_POSITION,
  FEEDBACK_CANDIDATE_ID: FEEDBACK_CANDIDATE_ID,
  HR_FEEDBACK_MODAL: HR_FEEDBACK_MODAL,
  ADD_PREVIOUS_ORG_MODAL: ADD_PREVIOUS_ORG_MODAL,
};

export const inputFieldTypeHash = {
  CANDIDATE_ROUND_CHANGE: "candidateRoundChange",
  CANDIDATE_JOB_CHANGE: "candidateJobChange",
  CANDIDATE_JOB_DESCRIPTION: "candidateJobDescription",
  CANDIDATE_JOB_DESCRIPTION_FOR_HISTORY: "candidateJobDescriptionForHistory",
  CANDIDATE_AGENCIES: "candidateAgencies",
  CANDIDATE_AGENCY_FOR_HISTORY: "candidateAgencyForHistory",
  CANDIDATE_AGENCY: "candidateAgency",
  INTERVIEW_TYPE: "interviewType",
  INTERVIEW_DATE: "interviewDate",
  INTERVIEW_AVAILABLE_SLOTS: "interviewAvailableSlots",
  INTERVIEW_PANELIST_NAME: "interviewPanelistName",
  INTERVIEW_SELF_BOOKED_SLOTS: "interviewSelfBookedSlots",
  INTERVIEW_SCHEDULE: "interviewSchedule",
  CANDIDATE_CLEAR: "candidates",
  CANDIDATE_RESUME: "candidateResume",
  CANDIDATE_IMAGE: "candidateImage",
  CANDIDATE_DATE_DROPDOWN: "candidateDateDropdown",
  CANDIDATE_DATE_RANGE: "candidateDateRange",
  CANDIDATE_STATUS_DROPDOWN: "candidateStatusDropdown",
  CANDIDATE_HACKATHON_STATUS_DROPDOWN: "candidateHackathonStatusDropdown",
  CANDIDATE_FILTER_STATUS: "candidateFilterStatus",
  FILTERED_STATUS_ONGOING:"filterStatusOngoing",
  CANDIDATE_FILTER_HACKATHON_STATUS: "candidateFilterHackathonStatus",
  CANDIDATE_ROUND: "candidateRound",
  CANDIDATE_NEW_PAGE_NUMBER: "candidateNewPageNumber",
  CANDIDATE_UPDATE_RESUME: "candidateupdatedResume",
  CANDIDATE_ONGOING_PAGE_NUMBER: "candidateOngoingPageNumber",
  CANDIDATE_REJECTED_PAGE_NUMBER: "candidateRejectedPageNumber",
  CANDIDATE_HISTORY_PAGE_NUMBER: "candidateHistoryPageNumber",
  CANDIDATE_PHOTO_EDIT: "candidatePhotoEdit",
  PANELIST: "panelist",
  CHANGED_PANELIST: "changedPanelist",

  CANDIDATE_EXPERIENCE_MAX: "candidateExperienceMax",
  CANDIDATE_EXPERIENCE_MIN: "candidateExperienceMin",
  EXPERIENCED_VACANCIES: "experiencedVacancies",
  TOTAL_EXPERIENCED_VACANCIES: "totalExperiencedVacancies",
  CANDIDATE_PROFILES: "candidateProfiles",
  CANDIDATE_TABLE_SEARCH: "candidateTableSearch",
  CANDIDATE_JOB_STATUS:"jobStatus",
  CANDIDATE_MAIL_TO: "candidateMailTo",
  SELECTED_ROW: "selectedRow",
  SHORTLISTER_COMMENT:"shortlisterComment",
  
};

export const candidateFieldTypeHash = {
  CANDIDATE_BLACKLIST: "blacklist",
  CANDIDATE_DUPLICATE: "duplicate",
  CANDIDATE_RECRUITMENT_STATUS: "candidateStatus",
  CANDIDATE_RECRUITMENT_ROUND: "currentRound",
  CANDIDATE_SHORTLISTER_NOTE: "shortlisterComment",
  CANDIDATE_PRIORITY:"candidatePriority",
};

export const operationTypeHash = {
  CANDIDATE_TABLE_LOADER: "candidateTableLoader",
  CANDIDATE_SCREEN_TYPE: "candidateScreenType",
  DUPLICATE_CANDIDATE_STATUS: "duplicateCandFilterStatus",
  CANDIDATE_JOB_CHANGE_MODAL: "candidateJobChangeModal",
  CANDIDATE_SHORTLISTED_LOADER: "candidateShortlistedLoader",
  CANDIDATE_REJECTED_LOADER: "candidateRejectedLoader",
  INTERVIEW_MODAL: "interviewModal",
  CANCEL_BUTTON:"cancelButton",
  RECORD_ID: "recordId",
  REASON_VAL:"reasonVal",
  OTHER_REASON_VAL:"otherReasonVal",
  INTERVIEW_SUBMIT_LOADER: "interviewSubmitLoader",
  INTERVIEW_ONMODAL_LOADER: "interviewOnModalLoader",
  INTERVIEW_DELETE_LOADER: "interviewDeleteLoader",
  INTERVIEW_CONFIRM_MODAL: "interviewConfirmModal",
  CANDIDATE_PDF_VIEW_MODAL: "candidatePDFViewModal",
  CANDIDATE_IMAGE_VIEW_MODAL: "candidateImageViewModal",
  DUPLICATE_VIEW_MODAL: "duplicateViewModal",
  CANDIDATE_PHOTO_LOADER: "candidatePhotoLoader",
  CANDIDATE_PHOTO_EDIT_BOOL: "candidatePhotoEditBool",
  SHOW_DOWNLOAD_CANDIDATE_MODEL: "showDownloadCandidateModal",

  // Candidate View Modal
  CANDIDATE_VIEW_MODAL: "candidateViewModal",
  CANDIDATE_EMAIL_MODAL: "candidateEmailModal",
  CANDIDATE_EMAIL_SUGGESTIONS: "candidateEmailSuggestion",
  EDIT_CANDIDATE_VIEW: "editCandidateView",
  MAIL_LOADING_UI: "mailLoadingUI",
  ADD_PROFILE_MODAL: "addProfileModal",

  //Feedback code
  FEEDBACK_ERROR_PAGE: "feedbackErrorPage",
  FEEDBACK_PAGE_LOADER: "feedbackPageLoader",
  FEEDBACK_SUBMIT_LOADER: "feedbackSubmitLoader",
  FEEDBACK_SCROLL_POSITION: "feedbackScrollPosition",
  FEEDBACK_SUBMITTED: "feedbackSubmitted",
  FEEDBACK_CANDIDATE_ID: "feedbackCandidateId",
  HR_FEEDBACK_MODAL: "hrFeedbackModal",
  ADD_PREVIOUS_ORG_MODAL: "addPreviousOrgModal",
};

export const badgeHash = {
  Shortlisted: "processing",
  Skipped: "processing",
  Scheduled: "warning",
  New: "warning",
  Selected: "success",
  "Not Selected": "error",
  Hold: "default",
  "In Progress": "success",
  "Feedback-Pending":"warning",
  "Re-Discussion":"processing",
  "Not Shortlisted": "error",
  All: "",
  New: "warning",
  "Re-Schedule":"processing",
  "Not Interested": "error",
  "Feedback-Pending": "purple",
  "ProposedJd": "processing",
  "Pre Shortlisting": "purple",
  "Pre Shortlisted" : "processing"
};

//Feedback code
export const feedbackOtherFieldType = {
  FEEDBACK_RECENT_PHOTO: FEEDBACK_RECENT_PHOTO,
  FEEDBACK_RECENT_PHOTO_URL: FEEDBACK_RECENT_PHOTO_URL,
};

export const feedbackOtherFieldTypeHash = {
  FEEDBACK_RECENT_PHOTO: "feedbackRecentPhoto",
  FEEDBACK_RECENT_PHOTO_URL: "feedbackRecentPhotoUrl",
};
