import React, { useEffect } from "react";
import classes from "./videos.module.css";
import { SearchOutlined, VideoCameraOutlined, } from "@ant-design/icons";
import { Input, Button, Typography, DatePicker, Space, Spin, Pagination } from "antd";
import filter from "../../assets/filter.png";
import Download from '../../assets/download.png';
import CopyIconVideo from "../../assets/CopyIconVideo.svg";
import VideoImage from "../../assets/video.svg";
import PlayIcon from "../../assets/playIcon.svg";
import { useState } from "react";
import OpenVideoModal from "./openVideoModal";
import { Api, Notification } from "services";
import { NavBar } from "./topNavBar";
import VideoModal from "./videoModal";
import { debounce } from "components/searchHandler";
import moment from "moment";
import BrigOpenVideoModal from "./BrigVideoModal";
// import { PiVideoFill } from "react-icons/pi";

const { RangePicker } = DatePicker;
const dateFormat = "YYYY-MM-DD";

const RecordVideos = () => {
  const [openModal, setOpenModal] = useState(false)
  const [searchVal, setSearch] = useState("");
  const [searchValTest, setSearchTest] = useState("");

  const [startDate, setStartDate] = useState("");
  const [startDateT, setStartDateT] = useState("");
  const [endDateT, setEndDateT] = useState("");
  const [endDate, setEndDate] = useState("");
  const [interviewData, setInterviewData] = useState([]);
  const [candidateDetails, setCandidateDetails] = useState([]);
  const [candidateId, setCandidateId] = useState(0);
 
  const [loader, setLoader] = useState(false);
  const [hoveredItem, setHoveredItem] = useState(null);
  const [hoveredItemLink, setHoveredItemLink] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(9);
  const [totalCount, setTotalCount] = useState(null);
  const [currentPageT, setCurrentPageT] = useState(1);
  const [pageSizeT, setPageSizeT] = useState(9);
  const [totalCountT, setTotalCountT] = useState(null);
  // const [currentItems, setCurrentItems] = useState([]);
  const [tab, setTab] = useState("Google Recorded Interviews")
  const [TestVideos, setTestVideos] = useState()
  const [TestVideosPlay, setTestVideosPlay] = useState([])

  // const [canSeleted, setSelected] = useState()
  const [isVisible, setVisible] = useState(false)
  const [isVisible2, setVisible2] = useState(false)
  const [loaderM, setLoaderM] = useState(false);
  const debouncedSetSearch = debounce(setSearchTest, 200);
  const [download, setDownload] = useState(false)



  // "Recorded Interviews","Recorded Hackathon Test"
  useEffect(() => {
    updateCurrentItems();
  }, [currentPage, interviewData,]);

  // const startIndex = (currentPage - 1) * pageSize;
  // const endIndex = startIndex + pageSize;

  const updateCurrentItems = () => {
    // setCurrentItems(interviewData.slice(startIndex, endIndex));
    // setCurrentItems(interviewData);
  };
  const handlePageChange = (page, pageSize) => {
    setCurrentPage(page);
    setPageSize(pageSize)
  };
  const handlePageChangeT = (page, pageSize) => {
    setCurrentPageT(page);
    setPageSizeT(pageSize)
  };
const getConferenceData = () =>{
  setLoader(true);
  Api.get("/hiring-videos/conferenceVideos")
  .params({
    search: searchVal,
    startDate: startDate,
    endDate: endDate,
    page: currentPage,
    limit: pageSize,
  })
  .send((res) => {
    if (typeof res != "undefined") {
      setLoader(false);
      setInterviewData(res?.data);
      setTotalCount(res?.count);
    }
  })
}
  const getData = () => {
    setLoader(true);
    Api.get("/hiring-videos/video")
      .params({
        search: searchVal,
        startDate: startDate,
        endDate: endDate,
        page: currentPage,
        limit: pageSize,
      })
      .send((res) => {
        if (typeof res != "undefined") {
          setLoader(false);
          setInterviewData(res?.data);
          setTotalCount(res?.count);
        }
      })
  }

  const getTestData = () => {
    setLoader(true);

    Api.post("/hiring-candidate/getScreenRecoding")
      .params({
        search: searchValTest,
        page: currentPageT,
        limit: pageSizeT,
        startDate: startDateT,
        endDate: endDateT,
        download: download
      })
      .send((res) => {
        if (download) {
          setLoader(false);
          setDownload(false)
          window.open(res, "_blank")
        } else {
          setTestVideos(res)
          setTotalCountT(res?.meta?.totalItems)
        }
        setLoader(false);
        setDownload(false)

      })
  }
  const getTestVideo = (id) => {
    setLoaderM(true);
    Api.post("/hiring-candidate/singleVideoData")
      .params({ candidateId: id })
      .send((res) => {
        setTestVideosPlay(res)
        setLoaderM(false);

      })
  }

  const downloadVideos = () => {
    if(tab==="Brigosha Recorded Interviews"){
      Api.get("/hiring-videos/conferenceVideos")
      .params({
        search: searchVal,
        startDate: startDate,
        endDate: endDate,
        download: true,
      })
      .send((res) => {
        if ( res) {
          console.log("wntywred");
          window.open(res, "_blank")?.focus();
          // setLoader(false);
          // setInterviewData(res?.data);
          // setTotalCount(res?.count);
        }
      })
      return
    }
    Api.get("/hiring-videos/video")
      .params({
        search: searchVal,
        startDate: startDate,
        endDate: endDate,
        download: true,
      })
      .send((response) => {
        if (typeof response != "undefined") {
          window.open(response, "_blank")?.focus();
        }
      })
  }
  useEffect(() => {
    
    getTestData()
  }, [tab === "Recorded Hackathon Test", searchValTest, currentPageT, pageSizeT, endDateT, download === true])

  useEffect(() => {
    setSearchTest("")
    setSearch("")
    setStartDateT("")
    setEndDateT("")
    setStartDate("")
    setEndDate("")

  }, [tab])


  useEffect(() => {
    console.log("dddd",tab);
    if(tab==="Recorded Hackathon Test"){
    return
    }
    if(tab==="Brigosha Recorded Interviews")
      {getConferenceData()

        return
      }
    getData();
  }, [searchVal, startDate, endDate, pageSize, currentPage,tab])

  const getDetails = (item) => {
    Api.get(`/hiring-videos/videoByCandidateName?candidateName=${item?.candidateName}&id=${item?.id}`)
      .send((res) => {
        setCandidateDetails(res);
      })
  }

  const copyLinkToClipboard = (item) => {
    const linkToCopy = item?.location;
    navigator.clipboard.writeText(linkToCopy)
      .then(() => {
        // console.log('Video link copied to clipboard:', linkToCopy);
        Notification.success('Video link copied');
      })
      .catch((error) => {
        console.error('Failed to copy video link to clipboard:', error);
      });
  };

  return (
    <>
      <div style={{ padding: "0.8% 2% 2% 2%", width: "100%", boxSizing: "border-box", height: "85vh", }}>
        <div >
          <NavBar data={["Google Recorded Interviews", "Recorded Hackathon Test","Brigosha Recorded Interviews"]} defaultView={"Google Recorded Interviews"} onClick={(res) => {
            setTab(res)
          }} />
        </div>
        {  tab==="Brigosha Recorded Interviews"? <>
              <div style={{ display: "flex", flexDirection: "row", color: "grey", gap: "1rem" }}>
                {/* <h1 style={{ fontWeight: "bold", fontSize: "1.5rem" }}>Google Recorded Interviews</h1> */}
                <p style={{ marginTop: "0.6rem" }}>( Displaying last 7 days data's... )</p>
              </div>
              <div style={{ display: 'flex', gap: "1rem", justifyContent: "space-between", width: "99%", }}>
                <div className={classes.searchDiv}>
                  <Input
                    type="text"
                    prefix={<SearchOutlined className="custom-search-icon" />}
                    size="medium"
                    allowClear={true}
                    value={searchVal}
                    placeholder=' Search'
                    style={{ color: "#161616", }}
                    onChange={(event) => {
                      setSearch(event.target.value)
                      // setPageSize(9)
                      setCurrentPage(1)
                    }}
                  />
                </div>
                <div style={{ display: "flex", flexDirection: "row", gap: "0.6rem" }}>
                  <img src={Download} style={{ width: "1.8rem", height: "1.8rem", cursor: "pointer" }}
                    onClick={downloadVideos} />
                  <RangePicker 
                   value={[
                    moment(startDate, "YYYY-MM-DD", true).isValid() ? moment(startDate, "YYYY-MM-DD") : null,
                    moment(endDate, "YYYY-MM-DD", true).isValid() ? moment(endDate, "YYYY-MM-DD") : null
                  ]}
                  format={dateFormat} onChange={(date, dateString) => {
                    setStartDate(dateString[0]);
                    setEndDate(dateString[1]);
                    // setPageSize(9)
                    setCurrentPage(1)
                  }} />
                </div>
              </div>
              {loader ?
                <div style={{ display: "flex", alignItems: "center", justifyContent: "center", margin: "10rem 9rem" }}>
                  <Spin />
                </div>
                :
                interviewData?.length > 0 ?
                  <div className={classes.scrollDiv}>
                    <div style={{ marginTop: "1rem", display: "grid", gridTemplateColumns: "repeat(3,1fr)", gap: "1.5rem", }}>

                      {interviewData?.map((item) => {
                        return (
                          <div >

                            <div style={{ backgroundColor: "white", height: "28vh", display: "flex", flexDirection: "row", }}
                            >
                              <div style={{ margin: "6vh 0.7vw", display: "flex", position: 'relative', alignItems: "center", flexDirection: "column", justifyContent: "flex-start", cursor: "pointer", }}
                                onClick={() => {
                                  setVisible2(true)
                                  setCandidateDetails(item)
                                  // setOpenModal(true)
                                  // getDetails(item);
                                  // setCandidateId(item?.id)
                                }}>
                                <video
                                  src={item?.videos?.[0]?.url}
                                  style={{ width: "100%", height: "100%", objectFit: "cover" }}
                                  // controls
                                  onMouseEnter={(event) => {
                                    event.target.play();
                                    setHoveredItem(item);
                                  }}
                                  onMouseLeave={(event) => {
                                    event.target.pause();
                                    setHoveredItem(null);
                                  }}
                                >
                                  play video
                                </video>
                                {hoveredItem !== item && <div style={{
                                  position: 'absolute', marginRight: "vw", top: '50%', left: '50%', transform: 'translate(-50%, -50%)',
                                  backgroundColor: 'rgba(0, 0, 0, 0.5)', color: '#fff', fontSize: '11px',
                                  display: "flex", flexDirection: "column", alignItems: "center", gap: "0.3rem"
                                }}
                                >
                                  <img src={PlayIcon} style={{ width: "5vw", height: "5vh", }} />
                                  <span>Click to play</span>
                                </div>}
                              </div>
                              <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", gap: "0.2rem", width: "21vw", margin: "2.5vh 0.4vw 0.5vh 0.2vw" }}>

                                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: "100%", }}>
                                  <h4 style={{}}>
                                    <span style={{ color: "#B3B3B3" }}>Candidate : </span>
                                    <span>
                                      <Typography.Text ellipsis={{ tooltip: true }} style={{ width: "5vw" }}>{item.candidate?.name}</Typography.Text>
                                    </span></h4>
                                  {/* <img src={CopyIconVideo}
                                    // style={{width:"1rem",height:"1rem",}} 
                                    style={{ width: "1rem", height: "0.9rem", marginRight: '3px', marginTop: "0.2rem", cursor: "pointer" }}
                                    onClick={() => copyLinkToClipboard(item)}
                                    onMouseEnter={() => setHoveredItemLink(item)} onMouseLeave={() => setHoveredItemLink(null)} /> */}
                                </div>

                                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: "100%", }}>
                                  <h4 style={{ marginTop: "0.8vh" }}><span style={{ color: "#B3B3B3" }}>Panelist : </span>
                                    <span>
                                      <Typography.Text ellipsis={{ tooltip: true }} style={{ width: hoveredItemLink === item ? "6vw" : "8vw" }}>{item.panelist?.name}</Typography.Text>
                                    </span></h4>
                                  {hoveredItemLink === item && (<span style={{ display: "flex", justifyContent: "flex-end", color: "#0086ff", marginTop: "-0.5rem" }}>copy</span>)}
                                </div>

                                <h4 style={{ marginTop: "0.8vh" }}><span style={{ color: "#B3B3B3", marginTop: "0.4rem" }}>Job : </span>
                                  <span>
                                    <Typography.Text ellipsis={{ tooltip: true }} style={{ width: "10vw" }}>{item.candidate?.job?.title}</Typography.Text>
                                  </span></h4>

                                <h4 style={{ marginTop: "0.8vh" }}><span style={{ color: "#B3B3B3" }}>Date : </span><span>
                                  <Typography.Text ellipsis={{ tooltip: true }} style={{ width: "7vw" }}>{item.date}</Typography.Text>
                                </span></h4>

                                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                  <h4 style={{ marginTop: "0.8vh" }}><span style={{ color: "#B3B3B3", }}>Round : </span>
                                    <span>{item.round}</span></h4>
                                  {/* <Button bordered={false} style={{background:"none",boxShadow:"none",border:"none",color:"#0086FF",fontSize:"0.7rem",
                        display:"flex",alignItems:"center",marginRight:"0.3rem",marginRight:"-0.5rem"}}
                        onClick={()=>{
                          Api.get("/hiring-videos/video")
                          .params({
                           search:searchVal,
                           startDate:startDate,
                           endDate:endDate,
                           download:true,
                           id:item.id,
                          })
                         .send((response)=>{
                          if (typeof response != "undefined") {
                            window.open(response, "_blank")?.focus();
                          }       
                         })
                        }} > */}
                                  <img src={Download} style={{ width: "1.5rem", height: "1.5rem", marginRight: '3px', cursor: "pointer" }}
                                    onClick={() => {
                                      if(tab==="Brigosha Recorded Interviews"){
                                        Api.get("/hiring-videos/conferenceVideos")
                                        .params({
                                         download:true,
                                         id:item?.meetingLink
                                          
                                        })
                                        .send((res) => {
                                          if (typeof res != "undefined") {
                                            window.open(res, "_blank")?.focus();
                                          }})
                                        return
                                      }
                                      Api.get("/hiring-videos/video")
                                        .params({
                                          search: searchVal,
                                          startDate: startDate,
                                          endDate: endDate,
                                          download: true,
                                          id: item.id,
                                        })
                                        .send((response) => {
                                          if (typeof response != "undefined") {
                                            window.open(response, "_blank")?.focus();
                                          }
                                        })
                                    }} />
                                  {/* Download */}
                                  {/* </Button> */}
                                </div>
                              </div>
                            </div>
                          </div>
                        )
                      })}
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: interviewData?.length > 0 ? '0.5rem' : "26rem" }}>
                      <Pagination
                        current={currentPage}
                        total={totalCount}
                        // size="small"
                        pageSize={pageSize}
                        pageSizeOptions={[9, 18, 30, 50,]}
                        showSizeChanger={true}
                        onChange={handlePageChange}
                        onShowSizeChange={handlePageChange}
                      />
                    </div>
                  </div>
                  : <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", height: "60vh", width: "75vw" }}>
                    <h3>No Data</h3>
                  </div>
              }

            </>:
          tab === "Google Recorded Interviews" ?
            <>
              <div style={{ display: "flex", flexDirection: "row", color: "grey", gap: "1rem" }}>
                {/* <h1 style={{ fontWeight: "bold", fontSize: "1.5rem" }}>Google Recorded Interviews</h1> */}
                <p style={{ marginTop: "0.6rem" }}>( Displaying last 7 days data's... )</p>
              </div>
              <div style={{ display: 'flex', gap: "1rem", justifyContent: "space-between", width: "99%", }}>
                <div className={classes.searchDiv}>
                  <Input
                    type="text"
                    prefix={<SearchOutlined className="custom-search-icon" />}
                    size="medium"
                    allowClear={true}
                    value={searchVal}
                    placeholder=' Search'
                    style={{ color: "#161616", }}
                    onChange={(event) => {
                      setSearch(event.target.value)
                      // setPageSize(9)
                      setCurrentPage(1)
                    }}
                  />
                </div>
                <div style={{ display: "flex", flexDirection: "row", gap: "0.6rem" }}>
                  <img src={Download} style={{ width: "1.8rem", height: "1.8rem", cursor: "pointer" }}
                    onClick={downloadVideos} />
                  <RangePicker 
                   value={[
                    moment(startDate, "YYYY-MM-DD", true).isValid() ? moment(startDate, "YYYY-MM-DD") : null,
                    moment(endDate, "YYYY-MM-DD", true).isValid() ? moment(endDate, "YYYY-MM-DD") : null
                  ]}
                  format={dateFormat} onChange={(date, dateString) => {
                    setStartDate(dateString[0]);
                    setEndDate(dateString[1]);
                    // setPageSize(9)
                    setCurrentPage(1)
                  }} />
                </div>
              </div>
              {loader ?
                <div style={{ display: "flex", alignItems: "center", justifyContent: "center", margin: "10rem 9rem" }}>
                  <Spin />
                </div>
                :
                interviewData?.length > 0 ?
                  <div className={classes.scrollDiv}>
                    <div style={{ marginTop: "1rem", display: "grid", gridTemplateColumns: "repeat(3,1fr)", gap: "1.5rem", }}>

                      {interviewData?.map((item) => {
                        return (
                          <div >

                            <div style={{ backgroundColor: "white", height: "28vh", display: "flex", flexDirection: "row", }}
                            >
                              <div style={{ margin: "6vh 0.7vw", display: "flex", position: 'relative', alignItems: "center", flexDirection: "column", justifyContent: "flex-start", cursor: "pointer", }}
                                onClick={() => {
                                  setOpenModal(true)
                                  getDetails(item);
                                  setCandidateId(item?.id)
                                }}>
                                <video
                                  src={item?.location}
                                  style={{ width: "100%", height: "100%", objectFit: "cover" }}
                                  // controls
                                  onMouseEnter={(event) => {
                                    event.target.play();
                                    setHoveredItem(item);
                                  }}
                                  onMouseLeave={(event) => {
                                    event.target.pause();
                                    setHoveredItem(null);
                                  }}
                                >
                                  play video
                                </video>
                                {hoveredItem !== item && <div style={{
                                  position: 'absolute', marginRight: "vw", top: '50%', left: '50%', transform: 'translate(-50%, -50%)',
                                  backgroundColor: 'rgba(0, 0, 0, 0.5)', color: '#fff', fontSize: '11px',
                                  display: "flex", flexDirection: "column", alignItems: "center", gap: "0.3rem"
                                }}
                                >
                                  <img src={PlayIcon} style={{ width: "5vw", height: "5vh", }} />
                                  <span>Click to play</span>
                                </div>}
                              </div>
                              <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", gap: "0.2rem", width: "21vw", margin: "2.5vh 0.4vw 0.5vh 0.2vw" }}>

                                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: "100%", }}>
                                  <h4 style={{}}>
                                    <span style={{ color: "#B3B3B3" }}>Candidate : </span>
                                    <span>
                                      <Typography.Text ellipsis={{ tooltip: true }} style={{ width: "5vw" }}>{item.candidateName}</Typography.Text>
                                    </span></h4>
                                  <img src={CopyIconVideo}
                                    // style={{width:"1rem",height:"1rem",}} 
                                    style={{ width: "1rem", height: "0.9rem", marginRight: '3px', marginTop: "0.2rem", cursor: "pointer" }}
                                    onClick={() => copyLinkToClipboard(item)}
                                    onMouseEnter={() => setHoveredItemLink(item)} onMouseLeave={() => setHoveredItemLink(null)} />
                                </div>

                                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: "100%", }}>
                                  <h4 style={{ marginTop: "0.8vh" }}><span style={{ color: "#B3B3B3" }}>Panelist : </span>
                                    <span>
                                      <Typography.Text ellipsis={{ tooltip: true }} style={{ width: hoveredItemLink === item ? "6vw" : "8vw" }}>{item.panelistName}</Typography.Text>
                                    </span></h4>
                                  {hoveredItemLink === item && (<span style={{ display: "flex", justifyContent: "flex-end", color: "#0086ff", marginTop: "-0.5rem" }}>copy</span>)}
                                </div>

                                <h4 style={{ marginTop: "0.8vh" }}><span style={{ color: "#B3B3B3", marginTop: "0.4rem" }}>Job : </span>
                                  <span>
                                    <Typography.Text ellipsis={{ tooltip: true }} style={{ width: "10vw" }}>{item.jobTitle}</Typography.Text>
                                  </span></h4>

                                <h4 style={{ marginTop: "0.8vh" }}><span style={{ color: "#B3B3B3" }}>Date : </span><span>
                                  <Typography.Text ellipsis={{ tooltip: true }} style={{ width: "7vw" }}>{item.interviewDate}</Typography.Text>
                                </span></h4>

                                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                  <h4 style={{ marginTop: "0.8vh" }}><span style={{ color: "#B3B3B3", }}>Round : </span>
                                    <span>{item.round}</span></h4>
                                  {/* <Button bordered={false} style={{background:"none",boxShadow:"none",border:"none",color:"#0086FF",fontSize:"0.7rem",
                        display:"flex",alignItems:"center",marginRight:"0.3rem",marginRight:"-0.5rem"}}
                        onClick={()=>{
                          Api.get("/hiring-videos/video")
                          .params({
                           search:searchVal,
                           startDate:startDate,
                           endDate:endDate,
                           download:true,
                           id:item.id,
                          })
                         .send((response)=>{
                          if (typeof response != "undefined") {
                            window.open(response, "_blank")?.focus();
                          }       
                         })
                        }} > */}
                                  <img src={Download} style={{ width: "1.5rem", height: "1.5rem", marginRight: '3px', cursor: "pointer" }}
                                    onClick={() => {
                                      Api.get("/hiring-videos/video")
                                        .params({
                                          search: searchVal,
                                          startDate: startDate,
                                          endDate: endDate,
                                          download: true,
                                          id: item.id,
                                        })
                                        .send((response) => {
                                          if (typeof response != "undefined") {
                                            window.open(response, "_blank")?.focus();
                                          }
                                        })
                                    }} />
                                  {/* Download */}
                                  {/* </Button> */}
                                </div>
                              </div>
                            </div>
                          </div>
                        )
                      })}
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: interviewData?.length > 0 ? '0.5rem' : "26rem" }}>
                      <Pagination
                        current={currentPage}
                        total={totalCount}
                        // size="small"
                        pageSize={pageSize}
                        pageSizeOptions={[9, 18, 30, 50,]}
                        showSizeChanger={true}
                        onChange={handlePageChange}
                        onShowSizeChange={handlePageChange}
                      />
                    </div>
                  </div>
                  : <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", height: "60vh", width: "75vw" }}>
                    <h3>No Data</h3>
                  </div>
              }

            </>
            :
            <>
              <div style={{ display: "flex", flexDirection: "row", color: "grey", gap: "1rem" }}>
                {/* <h1 style={{ fontWeight: "bold", fontSize: "1.5rem" }}>Google Recorded Interviews</h1> */}
                {
                  endDateT === "" ?
                    <>
                      <p style={{ marginTop: "0.6rem" }}>( Displaying last 7 days data's... )</p>
                    </>
                    :
                    <>
                    </>
                }
              </div>
              <div style={{ display: 'flex', gap: "1rem", justifyContent: "space-between", width: "99%", marginTop: 10 }}>
                <div className={classes.searchDiv}>
                  <Input
                    type="text"
                    prefix={<SearchOutlined className="custom-search-icon" />}
                    size="medium"
                    allowClear={true}
                    placeholder=' Search'
                    style={{ color: "#161616" }}
                    // value={searchValTest}
                    onChange={(event) => {
                      debouncedSetSearch(event.target.value)
                      // setPageSize(9)
                      // setCurrentPage(1)
                    }}
                  />
                </div>
                <div style={{ display: "flex", flexDirection: "row", gap: "0.6rem" }}>
                  <img src={Download} style={{ width: "1.8rem", height: "1.8rem", cursor: "pointer" }}
                    onClick={() => {
                      setDownload(true)
                    }} />

                  <RangePicker format={dateFormat}
                    value={[
                      moment(startDateT, "YYYY-MM-DD", true).isValid() ? moment(startDateT, "YYYY-MM-DD") : null,
                      moment(endDateT, "YYYY-MM-DD", true).isValid() ? moment(endDateT, "YYYY-MM-DD") : null
                    ]}
                    onChange={(date, dateString) => {
                      // console.log(dateString,"bcdate");
                      setStartDateT(dateString[0]);
                      setEndDateT(dateString[1]);
                      // setPageSize(9)
                      // setCurrentPage(1)
                    }} />
                </div>
              </div>


              {
                loader ?
                  <div style={{ display: "flex", alignItems: "center", justifyContent: "center", margin: "10rem 9rem" }}>
                    <Spin />
                  </div>
                  :
                  <>
                    {
                      TestVideos?.items?.length ?
                        <>
                          <div style={{ padding: 10, display: "flex", flexWrap: "wrap", color: "grey", gap: "1rem", marginTop: 20, height: "60vh", overflow: "scroll" }}>

                            {
                              TestVideos?.items?.map((item, index) => (
                                <>
                                  <div
                                    onClick={() => {
                                      // setSelected(item)
                                      getTestVideo(item?.id)
                                      setVisible(true)
                                    }}
                                    style={{ cursor: "pointer", padding: 10, width: "20rem", height: "12rem", boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px" }}>
                                    {/* <PiVideoFill /> */}
                                    <img src={VideoImage} style={{ height: "5vh", width: "15vw", padding: 5 }} />
                                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                                      <div style={{ display: "flex", alignItems: "flex-start", flexDirection: "column" }}>
                                        <div>
                                          Candidate name:
                                        </div>
                                        <div>
                                          Email:
                                        </div>
                                        <div>
                                          Current Company:
                                        </div>
                                        <div>
                                          Test name:
                                        </div>
                                        <div>
                                          Test date:
                                        </div>
                                        <div>
                                          Applied Job:
                                        </div>

                                      </div>
                                      <div style={{ display: "flex", alignItems: "flex-start", flexDirection: "column" }}>
                                        <div>
                                          <Typography.Text ellipsis={{ tooltip: true }} style={{ width: "10vw" }}>   {item?.name}</Typography.Text>
                                        </div>
                                        <div>
                                          <Typography.Text ellipsis={{ tooltip: true }} style={{ width: "10vw" }}> {item?.email}</Typography.Text>
                                        </div>
                                        <div>
                                          <Typography.Text ellipsis={{ tooltip: true }} style={{ width: "10vw" }}> {item?.currentCompany}</Typography.Text>
                                        </div>
                                        <div>
                                          <Typography.Text ellipsis={{ tooltip: true }} style={{ width: "10vw" }}> {item?.test?.title}</Typography.Text>
                                        </div>
                                        <div>
                                          <Typography.Text ellipsis={{ tooltip: true }} style={{ width: "10vw" }}> {item?.testDate}</Typography.Text>
                                        </div>
                                        <div>
                                          <Typography.Text ellipsis={{ tooltip: true }} style={{ width: "10vw" }}> {item?.job?.title}</Typography.Text>
                                        </div>
                                      </div>
                                    </div>

                                  </div>
                                </>
                              ))

                            }

                          </div>
                          <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: "1rem" }}>
                            <Pagination
                              current={currentPageT}
                              total={totalCountT}
                              // size="small"
                              pageSize={pageSizeT}
                              pageSizeOptions={[9, 18, 30, 50,]}
                              showSizeChanger={true}
                              onChange={handlePageChangeT}
                              onShowSizeChange={handlePageChangeT}
                            />
                          </div>
                        </>
                        :
                        <>
                          <div style={{ height: "80vh", width: "100%", display: "flex", alignItems: "center", justifyContent: "center", flexDirection: 'column', }}>
                            <div>No Data</div>
                          </div>
                        </>
                    }

                  </>
              }

            </>
        }
        {isVisible2&&<BrigOpenVideoModal openModal={isVisible2} candidateDetails={candidateDetails} setVisible2={setVisible2} />}
        <VideoModal videos={TestVideosPlay} loader={loaderM} isVisible={isVisible} setVisible={setVisible} />
        <OpenVideoModal openModal={openModal} candidateId={candidateId} setOpenModal={setOpenModal} candidateDetails={candidateDetails} />
      </div> 
    </>
  )
}

export default RecordVideos;