import React, { useState } from "react";
import { Modal,Input,Button,Typography,Popover } from "antd";
import Download from '../../assets/download.png';
import CopyIconVideo from "../../assets/CopyIconVideo.svg";
import { Api, Notification } from "services";
import { useEffect } from "react";
import PlayIcon from "../../assets/playIcon.svg";
import { useRef } from "react";

const BrigOpenVideoModal=(props)=>{

  const [details, setDetails] = useState(null);
  const [videoUrl,setVideoUrl]=useState()
  const [showCopyText, setShowCopyText] = useState(false);
  const videoRef = useRef(null);
  
  console.log("sssDEtails",props?.candidateDetails,details)
  useEffect(() => {
   
 if(props?.candidateDetails){
      setDetails(props?.candidateDetails)
      setVideoUrl(props?.candidateDetails?.videos[0]?.url)
    }
  }, [props.candidateDetails]);
  
  const copyLinkToClipboard = () => {
    const linkToCopy = videoUrl;
    navigator.clipboard.writeText(linkToCopy)
      .then(() => {
        console.log('Video link copied to clipboard:', linkToCopy);
        Notification.success('Video link copied');
      })
      .catch((error) => {
        console.error('Failed to copy video link to clipboard:', error);
      });
  };

  const downloadVideos= () => {
    Api.get("/hiring-videos/conferenceVideos")
    .params({
     download:true,
     id:details?.meetingLink
      
    })
    .send((res) => {
      if (typeof res != "undefined") {
        window.open(res, "_blank")?.focus();
      }
    })
    // Api.get('/hiring-videos/videoByCandidateName')
    //   .params({
    //     id:details?.id,
    //     download:true
    //   })
    //   .send((response)=>{
    //     if (typeof response != "undefined") {
    //       window.open(response, "_blank")?.focus();
    //     }  
    //   })
  }

  useEffect(() => {
    // if (props?.openModal) {
    //   if (videoRef.current) {
    //     videoRef.current.play();
    //   }
    // } 
    if (!props?.openModal) {
      if (videoRef.current) {
        videoRef.current.pause();
      }
    }
  }, [props?.openModal]);

   return(
        <Modal visible={props?.openModal} onCancel={()=>props?.setVisible2(false)}
         footer={false} closable={true} centered width="60vw">
            <div>
                <div style={{display:"flex",flexDirection:"row",padding:"1rem",}}>

                <div style={{margin:"1rem 1.5rem 1rem 0",display:"flex",alignItems:"center",height:"66vh",width:"35vw",
                     flexDirection:"column",justifyContent:"flex-start",cursor:"pointer"}}>
                    <video src={videoUrl} style={{ width: "100%", height: "100%", objectFit: "cover" }} 
                     controls ref={videoRef}>
                        play video
                    </video>
                </div>
                
                <div style={{display:"flex",flexDirection:"column",gap:"0.8rem",marginRight:"0.5rem"}}>
                    
                <div style={{display:"flex",border:"1px solid #D3D3D3",flexDirection:"column",justifyContent:"flex-start",padding:"1rem 0.5rem 1rem 0.7rem"}}>
                        
                        <h3 style={{fontWeight:"bold"}}>Information about the video currently being played.</h3>

                        <div style={{display:"flex",flexDirection:"row",justifyContent:"space-between",width:"100%",marginTop:"0.5rem"}}>
                        <h4><span style={{color:"#B3B3B3"}}>Candidate : </span>
                        <span>
                        <Typography.Text ellipsis={{tooltip:true}} style={{width:"11vw"}}>{details?.candidate?.name}</Typography.Text>
                        </span></h4>
                        {/* <Button bordered={false} style={{background:"none",boxShadow:"none",border:"none",display:"flex",flexDirection:"column",
                        justifyContent:"flex-end",textAlign:"right",marginRight:"-0.5rem"}} > */}
                        <img src={CopyIconVideo} style={{width:"1rem",height:"1rem",marginTop:"0.4rem",cursor:"pointer"}} onClick={copyLinkToClipboard}
                        onMouseEnter={() => setShowCopyText(true)} onMouseLeave={() => setShowCopyText(false)}/>
                        {/* </Button> */}
                        </div>

                        <div style={{display:"flex",flexDirection:"row",justifyContent:"space-between",width:"100%",}}>
                        <h4 style={{marginBottom:"0.3rem"}}><span style={{color:"#B3B3B3"}}>Panelist : </span>
                        <span>
                        <Typography.Text ellipsis={{tooltip:true}} style={{width:"9.4vw"}}>{details?.panelist?.name}</Typography.Text>
                        </span></h4>
                        {showCopyText && (<span style={{display:"flex",justifyContent:"flex-end",color:"#0086ff"}}>Copy link</span>)}
                        </div>

                        <h4 style={{marginBottom:"0.3rem"}}><span style={{color:"#B3B3B3",marginTop:"0.4rem"}}>Job : </span>
                        <span>{details?.candidate?.job?.title}</span></h4>

                        <h4 style={{marginBottom:"0.2rem"}}><span style={{color:"#B3B3B3"}}>Date : </span><span>
                        <Typography.Text ellipsis={{tooltip:true}} style={{width:"9vw"}}>
                        {details?.date?details?.date:"NA"}</Typography.Text>
                        </span></h4>
                        
                        <div style={{display:"flex",flexDirection:"row",justifyContent:"space-between"}}>
                        <h4 style={{marginTop:"0.5vh"}}><span style={{color:"#B3B3B3",}}>Round : </span>
                        <span>{details?.round}</span></h4>
                        {/* <Button style={{background:"none",boxShadow:"none",border:"none",color:"#0086FF",fontSize:"0.7rem",
                        display:"flex",alignItems:"center",marginRight:"0.3rem",marginRight:"-0.5rem"}} onClick={downloadVideos}> */}
                        <img src={Download} style={{width:"1.5rem",height:"1.5rem",marginRight: '3px',cursor:"pointer"}} onClick={downloadVideos}/>
                        {/* Download */}
                        {/* </Button> */}
                        </div>
                </div>

                <div style={{border:"1px solid #D3D3D3",padding:"1rem",height:"38vh"}}>
                    <h3>Related interviews of candidate</h3>

                {props?.candidateDetails?.videos?.length > 1 ?

                <div style={{display:"grid",gridTemplateColumns:"repeat(2,1fr)",gap:"1rem",overflowY:"scroll",height:"30vh",}}>

                  {props?.candidateDetails?.videos?.map((items)=>{
                      console.log("ssssItems",items)
                      if(videoUrl !== items.url){
                        return(
                          <div style={{marginTop:"0.5rem"}}>
                          <div style={{display:"flex",justifyContent:"flex-start",flexDirection:"row",height:"13vh",gap:"1rem",}}>
                          
                          <div style={{display:"flex",alignItems:"center",height:"13vh",}} 
                          onClick={()=>setVideoUrl(items?.url)}>
                          <video src={items?.url} style={{width:"100%",height:"100%",objectFit:"cover"}} 
                          // controls 
                          onMouseEnter={(event) => {
                              event.target.play();
                          }}
                            onMouseLeave={(event) => {
                              event.target.pause();
                          }}
                          >
                          play video
                          </video> 
                          </div>
                         
                          </div>
                          </div>
                        )
                      }
                      })
                     }
                </div>
                : 
                <div style={{margin:"4.7rem"}}><span>No Related Video</span></div>}
                </div>
                </div>
            </div>
            </div>
        </Modal>
    )
}
export default BrigOpenVideoModal;