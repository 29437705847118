import moment from "moment";
import {
  CANDIDATE_DETAILS_GET,
  operationType,
  operationTypeHash,
  inputFieldType,
  inputFieldTypeHash,
  candidateFormat,
  candidateFieldTypeHash,
  candidateFieldType,
  CANDIDATE_DETAILS,
  CANDIDATE_DETAILS_EDIT,
  CANDIDATE_RECRUITMENT_EDIT,
  INTERVIEW_SLOTS_ADD,
  PANELIST_NAME_CHANGED,
  INTERVIEW_SLOTS_DELETE,
  CANDIDATE_FEEDBACK,
  CANDIDATE_DETAILS_HIDE,
  candidateAgencyFormat,
  DUPLICATE_CANDIDATE_GET,
  CANDIDATE_SKIP_ROUND,
  CANDIDATE_COMMENT_TOGGLE,
  CANDIDATE_COMMENT_READ,
  CANDIDATE_COMMENT_LOADER,
  CANDIDATE_COMMENT_UPDATE_LOCAL,
  CANDIDATE_JOB_CHANGE_TOGGLE,
  CANDIDATE_ROUND_CHANGE_TOGGLE,
  CANDIDATE_JOB_CHANGE_LOADER,
  postDateFormat,
  UPDATE_CANDIDATE_JOBS,
  SET_FEEDBACK_VIEW,
  SET_CANDIDATE_SCREENSHOT,
  TOOGLE_FEEDBACK_LOADER,
  SET_SELECTED_CANDIDATE,
  SET_CANDIDATE_FEEDBACK,
  SELECT_CANDIDATE_ROUND,
  getCandidateFormat,
  FEEDBACK_DETAILS_GET,
  FEEDBACK_CANDIDATE_RESET,
  FEEDBACK_CANDIDATE_INFO_RESET,
  feedbackOtherFieldType,
  feedbackOtherFieldTypeHash,
  NOTICE_PERIOD_ORDER,

  GET_CANDIDATE_URL,
  GET_CANDIDATE_PARAMS,
  CANDIDATE_JOB_FILTER,
  CANDIDATE_EXPERIENCE_MAX
} from "./action";

let initialState = {
  candidateTableLoader: false,
  candidates: [],
  panelists: [],
  locations: [],
  candidateJobDescription: [],
  panelist: candidateFormat.panelist,
  changedPanelist: false,
  candidateJobDescriptionForHistory: [],
  candidateScreenType: "New",
  duplicateCandFilterStatus:"",
  candidateStatus: {},
  candidateInterviewMode: {},
  candidateAgencies: [{}],
  candidateJobs: [],
  candidateResetRounds: [{ title: "Round 0" }, { title: "Current Round" }],
  candidateOriginalInfo: candidateFormat,
  candidateInfo: candidateFormat,
  recordId:"",
  reasonVal:"",
  otherReasonVal:"",
  
  candidateViewModal: false,
  cancelButton:false,
  candidateEmailModal: false,
  candidateEmailSuggestion: false,
  editCandidateView:false,
  mailLoadingUI:false,
  addProfileModal:false,
  selectedRow:[],
  candidateProfiles:"",

  // Filter variables
  candidateJobFilter:[],
  candidateExperienceMax:"",
  candidateExperienceMin:"",
  candidateJobChangeModal: false,
  candidatePDFViewModal: false,
  candidateImageViewModal: false,
  candidateResume: "",
  candidateImage: "",
  candidateAgency: candidateAgencyFormat,
  candidateAgencyForHistory: [],
  candidateShortlistedLoader: false,
  candidateRejectedLoader: false,
  candidatePhoto: [],
  interviewModal: false,
  interviewSubmitLoader: false,
  interviewOnModalLoader: false,
  interviewDeleteLoader: false,
  interviewType: "Offline",
  interviewSchedule: {},
  interviewDate: null,
  interviewAvailableSlots: [],
  interviewSelfBookedSlots: [],
  interviewPanelistName: "",
  interviewConfirmModal: false,
  candidateDateDropdown: false,
  candidateDateRange: null,
  candidateStatusDropdown: false,
  candidateHackathonStatusDropdown: false,
  candidateFilterStatus: "All",
  candidateFilterHackathonStatus: "All",
  candidateRound: "All",
  duplicateViewModal: false,
  duplicateCandidates: [],
  candidateOngoingStatus: [],
  candidateRejectedStatus: [],
  candidateupdatedResume:"",
  candidateNewPageNumber: 1,
  candidateOngoingPageNumber: 1,
  candidateRejectedPageNumber: 1,
  candidateHistoryPageNumber: 1,
  candidateCommentBool: false,
  candidateCommentText: "",
  candidateCommentLoaderBool: false,
  candidateJobChange: {},
  candidateRoundChange: {},
  candidateJobChangeToggleBool: false,
  candidateJobChangeToggle: false,
  candidateRoundChangeToggle: false,
  candidateJobChangeLoaderBool: false,
  candidatePhotoEdit: {},
  candidatePhotoLoader: false,
  candidatePhotoEditBool: false,
  candidatePageMeta: {},
  viewFeedback: false,
  feedbackLoader: false,
  selectedCandidate: {},
  candidateFeedback: {},
  candidateRounds: [],
  candidatesScreenshot: [],
  candidateFeedbackRound: null,
  feedbackCandidate: {},
  feedbackPageLoader: false,
  feedbackSubmitLoader: false,
  feedbackCandidateInfo: getCandidateFormat,
  feedbackStatus: [],
  feedbackInterviewMode: [],
  feedbackScrollPosition: 0,
  feedbackSubmitted: false,
  feedbackCandidateId: null,
  feedbackQuestions: {},
  feedbackJobInterviewer: {},
  feedbackActions: {},
  feedbackCount: null,
  feedbackErrorPage: false,
  feedbackQuestionNotFound: false,
  maritalStatusList: [],
  automotiveBackground: [],
  feedbackRecentPhoto: [],
  hrFeedbackModal: false,
  addPreviousOrgModal: false,
  noticePeriodOrder: null,
  
  getCandidateUrl: "",
  getCandidateParams: {},
  showDownloadCandidateModal: false,
  proposeJob:[],
  candidateTableSearch:"",
  shortlisterComment:"",
  jobStatus:"Active",
  totalExperiencedVacancies:0,
  candidateMailTo:[],
};

const reducer = (state = initialState, action) => {

  switch (action.type) {
    case SELECT_CANDIDATE_ROUND:
      return {
        ...state,
        candidateFeedbackRound: action.val,
      };
    case SET_CANDIDATE_FEEDBACK:
      return {
        ...state,
        candidateFeedback: action.data,
        candidateRounds: action.data?.feedback?.map((item, index) => ({
          value: index,
          label: `Round ${item.round}`,
        })),
        candidatesScreenshot: action.data?.feedback?.map((item, index) => ({
          
          value: index,
          label: `${item.screenshot}`,
        })),
        candidateFeedbackRound: action.data?.feedback?.length ? 0 : null,
      };
    case SET_SELECTED_CANDIDATE:
      return {
        ...state,
        selectedCandidate: action.data,
      };
    case TOOGLE_FEEDBACK_LOADER:
      return {
        ...state,
        feedbackLoader: action.bool,
      };
    case SET_FEEDBACK_VIEW:
      return {
        ...state,
        viewFeedback: action.bool,
      };
    case SET_CANDIDATE_SCREENSHOT:
      return {
        ...state,
        candidatesScreenshot: action.value,
      };
    case CANDIDATE_SKIP_ROUND:
      return {
        ...state,
        candidates: state.candidates.map((candidate) =>
          action.id !== undefined && candidate.id === action.id
            ? {
                ...candidate,
                currentRound: action.value.candidateRound,
                candidateStatus: action.value.candidateStatus,
                feedback: [
                  ...candidate.feedback,
                  {
                    round: action.value.candidateRound,
                    status: action.value.status,
                    interviewDate: action.value.interviewDate,
                    createdAt: action.value.createdAt,
                    jobId: action.value.jobId,
                  },
                ],
              }
            : candidate
        ),
      };
    case DUPLICATE_CANDIDATE_GET:
      return {
        ...state,
        duplicateCandidates: action.value,
      };
    case CANDIDATE_DETAILS_GET:
      const candidates =
        action.value !== undefined
          ? action.value.candidates.items.map((candidate) => ({
              ...candidate,
              key: candidate.id,
            }))
          : [];


      return {
        ...state,
        candidates: candidates,
        candidateStatus: action.value.candidateStatus,
        candidateAgencies: action.value.agencies,
        candidateInterviewMode: action.value.interviewMode,
        candidateOngoingStatus: action.value.candidateOngoingStatus,
        candidateRejectedStatus: action.value.candidateRejectedStatus,
        candidatePageMeta: action.value.candidates.meta,
        panelists: action.value.panelists,
        locations: action.value.locations,
        proposeJob:action.value.job,
        candidateProfiles: action.value.profileCount
      };
    case UPDATE_CANDIDATE_JOBS:
      return {
        ...state,
        candidateJobs: action.jobs,
      };
    case CANDIDATE_DETAILS_EDIT:
      return {
        ...state,
        candidates: state.candidates?.map((candidate) =>
          action.id !== undefined && candidate.id === action.id
            ? action.candidate
            : candidate
        ),
        candidateInfo: action.candidate,
      };
    case CANDIDATE_DETAILS_HIDE:
      return {
        ...state,
        candidates: state.candidates.filter(
          (candidate) => candidate.id !== action.id
        ),
      };
    case CANDIDATE_RECRUITMENT_EDIT:
      return {
        ...state,
        candidates: state.candidates.map((candidate) =>
          action.id !== undefined && candidate.id === action.id
            ? {
                ...candidate,
                [candidateFieldTypeHash[action.kind]]: action.value,
              }
            : candidate
        ),
        candidateInfo: {
          ...state.candidateInfo,
          [candidateFieldTypeHash[action.kind]]: action.value,
        },
      };
    case INTERVIEW_SLOTS_ADD:
      return {
        ...state,
        candidates: state.candidates.map((candidate) =>
          action.id !== undefined && candidate.id === action.id
            ? {
                ...candidate,
                interviewSlots: action.value,
              }
            : candidate
        ),
        candidateInfo: { ...state.candidateInfo, interviewSlots: action.value },
      };
    case PANELIST_NAME_CHANGED:
      return {
        ...state,
        candidates: state.candidates.map((candidate) =>
          action.id !== undefined && candidate.id === action.id
            ? {
                ...candidate,
                panelist: action.value,
              }
            : candidate
        ),
        candidateInfo: { ...state.candidateInfo, panelist: action.value },
      };
    case INTERVIEW_SLOTS_DELETE:
      return {
        ...state,
        candidates: state.candidates.map((candidate) =>
          action.id !== undefined && candidate.id === action.id
            ? {
                ...candidate,
                interviewSlots: [],
              }
            : candidate
        ),
        candidateInfo: { ...state.candidateInfo, interviewSlots: [] },
      };
    case CANDIDATE_FEEDBACK:
      return {
        ...state,
        candidates: state.candidates.map((candidate) =>
          action.id !== undefined && candidate.id === action.id
            ? {
                ...candidate,
                feedback: [...candidate.feedback, action.value],
              }
            : candidate
        ),
      };
    case operationType[action.type]:
      return {
        ...state,
        [operationTypeHash[action.type]]: action.value,
      };
    case inputFieldType[action.type]:
      return {
        ...state,
        [inputFieldTypeHash[action.type]]: action.value,
      };
    case CANDIDATE_DETAILS: {
     
      return {
        ...state,
        candidateInfo: action.value,
        candidateOriginalInfo: action.value,
      };
    }
    
    case candidateFieldType[action.type]:
      return {
        ...state,
        candidateInfo: {
          ...state.candidateInfo,
          [candidateFieldTypeHash[action.type]]: action.value,
        },
      };
    case CANDIDATE_COMMENT_TOGGLE:
      return {
        ...state,
        candidateCommentBool: action.id,
        candidateCommentText: action.text === null ? "" : action.text,
      };
    case CANDIDATE_COMMENT_UPDATE_LOCAL:
      state.candidates.map((item, index) => {
        if (action.id === item.id) {
          item.comment = action.text;
        }
      });
      return {
        ...state,
        candidates: [...state.candidates],
      };
    case CANDIDATE_COMMENT_READ:
      return {
        ...state,
        candidateCommentText: action.input,
      };
    case CANDIDATE_COMMENT_LOADER:
      return {
        ...state,
        candidateCommentLoaderBool: action.bool,
      };
    case CANDIDATE_JOB_CHANGE_TOGGLE:
      return {
        ...state,
        candidateJobChangeToggleBool: action.bool,
        candidateJobChange: action.obj,
      };
    case CANDIDATE_ROUND_CHANGE_TOGGLE:
      return {
        ...state,
        candidateRoundChangeToggleBool: action.bool,
        candidateRoundChange: action.obj,
      };
    case CANDIDATE_JOB_CHANGE_LOADER:
      return {
        ...state,
        candidateJobChangeLoaderBool: action.bool,
      };

    case FEEDBACK_DETAILS_GET:
      const checkQuestions =
        action.value.feedbackQuestions &&
        action.value.feedbackQuestions.questions
          ? true
          : false;
      return {
        ...state,
        feedbackCandidateInfo: action.value.candidate,
        feedbackJobInterviewer: action.value.jobInterviewer,
        maritalStatusList: action.value.maritalStatus,
        automotiveBackground: action.value.automotiveBackground,
        feedbackQuestions: action.value.feedbackQuestions,
        feedbackCandidate: checkQuestions
          ? feedbackInputKeys(action.value.feedbackQuestions.questions)
          : {},
        feedbackActions: checkQuestions
          ? feedbackInputActions(action.value.feedbackQuestions.questions)
          : {},
        feedbackCount: checkQuestions
          ? feedbackInputCount(action.value.feedbackQuestions.questions)
          : null,
        feedbackStatus: action.value.status,
        feedbackInterviewMode: action.value.interviewMode,
        feedbackQuestionNotFound: checkQuestions ? false : true,
      };
    case operationType[action.type]:
      return {
        ...state,
        [operationTypeHash[action.type]]: action.value,
      };
    case FEEDBACK_CANDIDATE_RESET:
      return {
        ...state,
        feedbackCandidate: {},
      };
    case FEEDBACK_CANDIDATE_INFO_RESET:
      return {
        ...state,
        feedbackCandidateInfo: getCandidateFormat,
      };
    case feedbackOtherFieldType[action.type]:
      return {
        ...state,
        feedbackCandidateInfo: {
          ...state.feedbackCandidateInfo,
          [feedbackOtherFieldTypeHash[action.type]]: action.value,
        },
      };
    case NOTICE_PERIOD_ORDER:
      // if (action.value == 'null') {
      //   return {
      //     ...state,
      //     noticePeriodOrder: null,
      //   };
      // } else {
      return {
        ...state,
        noticePeriodOrder: action.value,
      };
      
    // }
    case GET_CANDIDATE_URL:
      return {
        ...state,
        getCandidateUrl: action.value,
    };

    case GET_CANDIDATE_PARAMS:
      return {
        ...state,
        getCandidateParams: action.value,
    };

    case CANDIDATE_JOB_FILTER:
      return{
        ...state,
        candidateJobFilter: action.jobFilter,
      }
    
    default:
      return state;
  }
  
};




export default reducer;
